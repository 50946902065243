import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import LinkBlock from 'components/LinkBlock';
import ContentBlock from 'components/ContentBlock';
import TitleBlock from 'components/TitleBlock';
import Illustration from 'components/Illustration';
import Cards from './sections/Cards';

import vw from 'src/styles/utils';

const Philosophy = (props) => {
  const handleVideo = () => {
    // Video Handler on Button Click
  };

  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page>
              <Hero
                subheader={t.philosophy.subheader}
                header={t.philosophy.header}
                video="https://player.vimeo.com/external/492212573.hd.mp4?s=4097e95de3dfc5f74e74363ecfc1c0ea4beb9641&amp;profile_id=174"
                popupVideo="https://player.vimeo.com/external/457107513.hd.mp4?s=b119b97c2981802058fff7531e8ba24c49e55c32&amp;profile_id=175"
                hidePlayButton
              >
                {/* <HeroImage
                  src={require('src/assets/images/philosophy/art-lives-here.png')}
                  alt=""
                /> */}
              </Hero>
              <Space />
              {t.philosophy.contentBlock.map((section, i) => (
                <ContentBlock
                  key={i}
                  title={section.title}
                  body={section.body}
                  images={section.images}
                  settings={section.settings}
                  video={section.video}
                  handleVideo={handleVideo}
                >
                  {i === 0 && (
                    <Illustration
                      mobile={{
                        width: 398,
                        height: 345,
                        top: 485,
                        left: 105,
                      }}
                      tablet={{
                        width: 723,
                        height: 690,
                        top: 802,
                        left: 325,
                      }}
                      desktop={{
                        width: 723,
                        height: 690,
                        top: -100,
                        left: -300,
                      }}
                      distance={20}
                      image="philosophy/jinray-logo.png"
                    />
                  )}
                </ContentBlock>
              ))}
              <TitleBlock
                subTitle={t.philosophy.art.subTitle}
                title={t.philosophy.art.title}
                text={t.philosophy.art.text}
                image={require('src/assets/images/philosophy/gryphon-logomark.png')}
              />
              <Cards data={t.philosophy.cards} />
              <LinkBlock
                title={t.philosophy.linkBlock.title}
                body={t.philosophy.linkBlock.body}
                buttonLabel={t.philosophy.linkBlock.button}
                bgImageMobile="philosophy/taizo-yamamoto-desk-tall.jpeg"
                bgImageDesktop="philosophy/taizo-yamamoto-desk-wide.jpeg"
                url="/creation"
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Philosophy;

const Root = styled.div``;

const HeroImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 288, 678)}
`;

const Space = styled.div`
  ${vw('margin-bottom', 80)};
`;
