export default {
  color: {
    purple: '#612a45',
    darkBrown: '#322625',
    taupe: '#e0ddd1',
    white: '#ffffff',
    moss: '#9c967b',
  },
  font: {
    family: 'SharpSans',
    header: 'Ogg-Roman',
    sc: 'Noto Sans SC',
    tc: 'Noto Sans TC',
    ch: 'gh-ch',
  },
  animation: {
    timingFunction: 'cubic-bezier(.3,.12,.56,.91)',
    time: '0.4s',
  },
  shadow: '0 4px 12px 1px rgba(0, 0, 0, 0.06), 0 6px 16px 0 rgba(0, 0, 0, 0.1)',
};
