import { keyframes, css } from 'styled-components';
import theme from './theme';

const BlurFadeIn = keyframes`
  0% {
    opacity: 0;
    filter: blur(40px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
`;

export const BlurFadeInCss = css`
  animation-name: ${BlurFadeIn};
  animation-duration: ${theme.animation.time};
  animation-timing-function: ${theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;

const FadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeUpCss = css`
  animation-name: ${FadeUp};
  animation-duration: 0.6s;
  animation-timing-function: ${theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: ${(props) => 0.05 * props.index + 0.8}s;
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FadeInCss = css`
  animation-name: ${FadeIn};
  animation-duration: ${theme.animation.time};
  animation-timing-function: ${theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
`;

const ScaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(0.2);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
`;

export const ScaleInCss = css`
  animation-name: ${ScaleIn};
  animation-duration: 2.5s;
  animation-timing-function: ${theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
  transform-origin: ${(props) => (props.left ? 'right' : 'left')};
`;

const ScaleFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1.05);
  }
`;

export const ScaleFadeInCss = css`
  animation-name: ${ScaleFadeIn};
  animation-duration: 5s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;
