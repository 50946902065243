import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animations/FadeUp';
import Card from '../elements/Card';

import vw from 'src/styles/utils';

const Cards = (props) => {
  const { data } = props;

  return (
    <Root>
      {
        data.map((card, index) => (
          <FadeUp>
            <Card 
              key={index}
              logo={card.logo}
              logoSize={card.logoSize}
              body={card.body}
              background={card.background}
            />
          </FadeUp>
        ))
      }
    </Root>
  )
}

export default Cards;

Cards.propTypes = {
  data: PropTypes.array,
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('margin-top', 40, 80, 160)}
  ${vw('margin-bottom', 60, 140)}
`;

