import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Intl from 'components/Intl';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const VideoPopup = (props) => {
  const { active, src, handleClose } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const $popup = useRef();
  const $video = useRef();

  useEffect(() => {
    if (active) {
      // $video.current.muted = false;
      // setIsMuted(false);
      $video.current.play();
      setIsPlaying(true);
      disableBodyScroll($popup.current);
    } else {
      $video.current.pause();
      setIsPlaying(false);
      enableBodyScroll($popup.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [active]);

  const togglePlay = () => {
    if (isPlaying) {
      $video.current.pause();
      setIsPlaying(false);
    } else {
      $video.current.play();
      setIsPlaying(true);
    }
  };

  const replay = () => {
    $video.current.currentTime = 0;
    if (!isPlaying) {
      $video.current.play();
      setIsPlaying(true);
    }
  };

  const handleSkip = (seconds) => {
    $video.current.currentTime += seconds;
  };

  const toggleMute = () => {
    if (isMuted) {
      $video.current.muted = false;
      setIsMuted(false);
    } else {
      $video.current.muted = true;
      setIsMuted(true);
    }
  };

  const handleCloseClick = () => {
    handleClose();
    $video.current.pause();
    setIsPlaying(false);
  };

  return (
    <Intl>
      {(t) => (
        <Root
          style={{
            opacity: active ? 1 : 0,
            pointerEvents: active ? 'all' : 'none',
          }}
          ref={$popup}
        >
          <Overlay />
          <div>
            <Video ref={$video} src={src} muted={isMuted} playsInline />
            <Controls>
              <img
                src={require('src/assets/images/icons/icon-replay.png')}
                alt="Replay"
                onClick={replay}
              />
              <img
                src={require('src/assets/images/icons/icon-rewind15.png')}
                alt="Rewind 15s"
                onClick={() => handleSkip(-15)}
              />
              <img
                src={
                  isPlaying
                    ? require('src/assets/images/icons/icon-pause.png')
                    : require('src/assets/images/icons/icon-play.png')
                }
                alt="Play/Pause"
                className="middle"
                onClick={togglePlay}
              />
              <img
                src={require('src/assets/images/icons/icon-fastforward15.png')}
                alt="Fast Forward 15s"
                onClick={() => handleSkip(15)}
              />
              <img
                src={
                  isMuted
                    ? require('src/assets/images/icons/icon-sound-on.png')
                    : require('src/assets/images/icons/icon-sound-off.png')
                }
                alt="Sound On/Off"
                onClick={toggleMute}
                className={
                  isMuted
                    ? "pulsing"
                    : ""
                }
              />
            </Controls>
          </div>
          <Close onClick={handleCloseClick}>
            <p className="button light">{t.menuClose}</p>
            <img
              src={require('src/assets/images/icons/close-taupe.svg')}
              alt="Close"
            />
          </Close>
        </Root>
      )}
    </Intl>
  );
};

VideoPopup.propTypes = {
  active: PropTypes.bool,
  src: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(${require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg')});
  background-size: cover;
  background-position: center;
  z-index: 100;
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
`;

const Video = styled.video`
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: ${vwMobile(168)};
  @media ${media.tablet} {
    top: ${vwTablet(266)};
  }
  @media ${media.desktop} {
    top: 0;
    height: 100%;
  }
`;

const Controls = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: ${vwMobile(168)};
  width: 100%;
  img {
    width: ${vwMobile(32)};
    height: ${vwMobile(32)};
    position: relative;
    z-index: 1;
    &:first-of-type {
      margin-right: ${vwMobile(40)};
    }
    &.middle {
      margin: 0 ${vwMobile(16)};
    }
    &:last-of-type {
      margin-left: ${vwMobile(40)};
    }
  }
  img.pulsing{ 
    animation: animate 1s linear infinite;
    }
  @keyframes animate { 
            0% { 
              opacity: 1; 
            } 
            50% { 
              opacity: .2; 
            } 
            100% { 
              opacity: 1; 
            } 
        }
  @media ${media.tablet} {
    bottom: ${vwTablet(266)};
    img {
      width: ${vwTablet(40)};
      height: ${vwTablet(40)};
      &:first-of-type {
        margin-right: ${vwTablet(100)};
      }
      &.middle {
        margin: 0 ${vwTablet(20)};
      }
      &:last-of-type {
        margin-left: ${vwTablet(100)};
      }
    }
  }
  @media ${media.desktop} {
    bottom: ${vwDesktop(24)};
    img {
      width: ${vwDesktop(40)};
      height: ${vwDesktop(40)};
      &:first-of-type {
        margin-right: ${vwDesktop(100)};
      }
      &.middle {
        margin: 0 ${vwDesktop(24)};
      }
      &:last-of-type {
        margin-left: ${vwDesktop(100)};
      }
    }
    &:after {
      content: '';
      pointer-events: none;
      width: 100vw;
      height: ${vwDesktop(80)};
      position: absolute;
      left: 0;
      bottom: ${vwDesktop(-24)};
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      );
      z-index: 0;
    }
  }
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: ${vwMobile(40)};
  left: 50%;
  transform: translateX(-50%);
  p {
    line-height: 1;
  }
  img {
    width: ${vwMobile(13)};
    height: ${vwMobile(13)};
    display: block;
    margin-left: ${vwMobile(10)};
  }
  @media ${media.tablet} {
    bottom: ${vwTablet(60)};
    img {
      width: ${vwTablet(16)};
      height: ${vwTablet(16)};
      margin-left: ${vwTablet(10)};
    }
  }
  @media ${media.desktop} {
    right: ${vwDesktop(42)};
    bottom: ${vwDesktop(24)};
    left: auto;
    transform: translateX(0%);
    img {
      width: ${vwDesktop(16)};
      margin-left: ${vwDesktop(10)};
    }
  }
`;

export default VideoPopup;
