import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Register from './elements/Register';
import Info from './elements/Info';

const Footer = (props) => {
  const { hideRegister } = props;

  return (
    <Root>
      {!hideRegister && <Register />}
      <Info />
    </Root>
  );
};

Footer.propTypes = {
  hideRegister: PropTypes.bool,
};

const Root = styled.div``;

export default Footer;
