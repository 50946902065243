import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import ImageCallout from 'components/layout/ImageCallout';

import { vwDesktop, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Callouts = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Wrapper>
            <ImageCallout
              image={require('src/assets/images/location/newyork-highline.jpg')}
              text={t.location.callouts[0].text}
              parallax={[-10, 10]}
              width={495}
            />
            <ImageCallout
              image={require('src/assets/images/location/paris-champs-elysees.jpg')}
              text={t.location.callouts[1].text}
              parallax={[-10, 10]}
              width={390}
            />
          </Wrapper>
          <StyledImageCallout
            image={require('src/assets/images/location/barcelona-las-ramblas.jpg')}
            text={t.location.callouts[2].text}
            parallax={[-10, 10]}
            width={440}
          />
          <Space />
          <ImageCallout
            image={require('src/assets/images/location/arbutus-greenway-rendering.jpg')}
            text={t.location.callouts[3].text}
            parallax={[-5, 5]}
            fullWidth
            width={1220}
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin-top: ${vwMobile(80)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(160)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(160)};
    padding: 0 ${vwDesktop(60)};
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    padding: 0 ${vwDesktop(103)};
    > div:nth-of-type(2) {
      margin-top: ${vwDesktop(483)};
    }
  }
`;

const StyledImageCallout = styled(ImageCallout)`
  @media ${media.desktop} {
    margin-top: ${vwDesktop(-280)};
    margin-left: ${vwDesktop(182)};
  }
`;

const Space = styled.div`
  @media ${media.desktop} {
    padding-top: ${vwDesktop(160)};
  }
`;

export default Callouts;
