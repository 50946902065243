import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import GradientText from 'components/typography/GradientText';
import Lines from 'components/Lines';

import vw, { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Intl from 'components/Intl';

const LinkBlock = (props) => {
  const {
    subtitle,
    title,
    bgImageMobile,
    bgImageDesktop,
    body,
    buttonLabel,
    url,
    noLogo,
    small,
  } = props;
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <>
          <Root
            bgImageMobile={require(`src/assets/images/${bgImageMobile}`)}
            bgImageDesktop={require(`src/assets/images/${bgImageDesktop}`)}
            small={small}
          >
            <Overlay />
            <Top>
              <Lines mobile />
            </Top>
            <Container>
              {subtitle && <h3 className="subheader light">{subtitle}</h3>}
              <GradientText>
                <h2>
                  <span></span>
                  {title}
                </h2>
              </GradientText>
              <p>{body}</p>
              <StyledLink to={getLocaleURL() + url} className="button">
                {buttonLabel}
              </StyledLink>
            </Container>
            <Bottom noLogo={noLogo}>
              <Lines mobile />
              {!noLogo && (
                <Logo>
                  <img
                    src={require('src/assets/images/logos/logo-atelier-gold.svg')}
                    alt="atelier"
                  />
                </Logo>
              )}
              {!noLogo && <Lines mobile />}
            </Bottom>
          </Root>
        </>
      )}
    </Intl>
  );
};

export default LinkBlock;

LinkBlock.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  bgImageMobile: PropTypes.string,
  bgImageDesktop: PropTypes.string,
  body: PropTypes.string,
  butonLabel: PropTypes.string,
  url: PropTypes.string,
  noLogo: PropTypes.bool,
  small: PropTypes.bool,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  background: url(${(props) => props.bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${(props) => (props.small ? vw('height', 400, 800) : vw('height', 470, 800))};

  @media ${media.tablet} {
    display: flex;
    align-items: center;
  }

  @media ${media.desktop} {
    background: url(${(props) => props.bgImageDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);

  @media ${media.tablet} {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  @media ${media.desktop} {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 35%,
      rgba(0, 0, 0, 0) 89%
    );
  }
`;

const Container = styled.div`
  position: absolute;
  top: ${vwMobile(77)};
  ${vw('width', 288, 450, 495)}
  ${vw('left', 16, 40, 163)}

  h2 {
    ${vw('margin-bottom', 20, 40)}
    span {
      font-style: italic;
    }
  }

  h3 {
    text-transform: uppercase;
    ${vw('margin-bottom', 32, 80)}
  }

  p {
    color: white;
    ${vw('width', 288, 450, 391)}
    ${vw('margin-bottom', 20, 40)}
  }

  @media ${media.tablet} {
    position: relative;
    top: auto;
    /* top: 50%;
    transform: translateY(-50%); */
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.color.taupe};
  color: ${({ theme }) => theme.color.taupe};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  ${vw('padding-top', 6, 9)}
  ${vw('padding-right', 16, 24)}
  ${vw('padding-bottom', 6, 9)}
  ${vw('padding-left', 16, 24)}
  ${vw('border-radius', 14, 18)}

  &:hover {
    background-color: ${({ theme }) => theme.color.taupe};
    color: ${({ theme }) => theme.color.darkBrown};
  }
`;

const Top = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${vw('height', 5)}
  ${vw('width', 288, 688, 1220)}
  ${vw('top', 32, 60)}
`;

const Bottom = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.noLogo ? vw('bottom', 32, 60) : vw('bottom', 21, 37))}
  ${vw('padding-left', 16, 40, 60)}
  ${vw('padding-right', 16, 40, 60)}
`;

const Logo = styled.div`
  img {
    ${vw('width', 104, 180)}
    ${vw('margin-right', 12, 24)}
    ${vw('margin-left', 12, 24)}
  }
`;
