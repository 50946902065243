import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import GradientText from './GradientText';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const GradientButton = (props) => {
  const { text, to, background } = props;
  return (
    <Root to={to}>
      <Wrapper background={background}>
        <p className="button">
          {' '}
          <GradientText>{text}</GradientText>
        </p>
        <span className="hover">
          <p className="button taupe">{text}</p>
        </span>
      </Wrapper>
    </Root>
  );
};

GradientButton.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
};

const Root = styled(Link)`
  padding: 1px;
  background: transparent;
  border-radius: ${vwMobile(18)};
  border: 1px transparent;
  background-image: linear-gradient(
    115deg,
    #f5d696 23%,
    #f4e4a8 35%,
    #edd397 49%,
    #deb66d 59%,
    #dea960 76%
  );
  display: block;
  position: relative;
  .hover {
    display: none;
  }
  @media ${media.tablet} {
    border-radius: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    position: relative;
    border-radius: ${vwDesktop(24)};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
    .hover {
      display: block;
      position: absolute;
      /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: ${vwDesktop(9)} ${vwDesktop(24)};
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
      color: ${({ theme }) => theme.color.taupe};
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      border: 1px transparent;
      border-radius: ${vwDesktop(24)};
      background-color: #e0ddd1;
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
      opacity: 0;
      z-index: 1;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      .hover {
        opacity: 1;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${vwMobile(6)} ${vwMobile(16)};
  border-radius: ${vwMobile(18)};
  background-color: ${(props) => props.background};
  position: relative;
  z-index: 2;
  @media ${media.tablet} {
    padding: ${vwTablet(9)} ${vwTablet(24)};
    border-radius: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(9)} ${vwDesktop(24)};
    border-radius: ${vwDesktop(24)};
  }
`;

export default GradientButton;
