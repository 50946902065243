import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import { vwMobile, vwTablet, vwDesktop } from './utils';
import media from './media';

export default createGlobalStyle`

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(70px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInUp {
    animation: fadeInUp ${theme.animation.time} ${
  theme.animation.timingFunction
};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font.family};
  }

  img {
    width: 100%;
    height: auto;
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    display: inline-block;
  }

  main {
    width: 100%;
    height: 100%;
  }

  .dark {
    color: ${theme.color.darkBrown};
  }
 

  .light {
    color: ${theme.color.taupe};
  }

  .purple {
    color: ${theme.color.purple};
  }

  .moss {
    color: ${theme.color.moss};
  }

  .mobile-menu {
    padding-top: max(${vwMobile(45)}, 8vh);
    padding-bottom: max(${vwMobile(85)}, 15vh);
    @media ${media.tablet} {
      padding-top: ${vwTablet(100)};
      padding-bottom: ${vwTablet(150)};
    }
    @media ${media.desktop} {
      padding: 0;
    }
  }

  .regular-heading {
    font-family: ${theme.font.header} !important;
    font-style: normal !important;
  }

  h1 {
    font-family: ${theme.font.header};
    font-size: ${vwMobile(40)};
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: ${vwMobile(0.45)};
    @media ${media.tablet} {
      font-size: ${vwTablet(68)};
      letter-spacing: ${vwTablet(0.45)};
    }
    @media ${media.desktop} {
      font-size: ${vwDesktop(68)};
      line-height: 1.24;
      letter-spacing: ${vwDesktop(0.45)};
    }
  }

  .sc h1, .tc h1 {
    font-family: ${theme.font.ch};
  }

  h2 {
    font-family: ${theme.font.header};
    font-weight: normal;
    line-height: 1.29;
    font-size: ${vwMobile(28)};
    letter-spacing: ${vwMobile(0.25)};
    @media ${media.tablet} {
      line-height: 1.25;
      font-size: ${vwTablet(56)};
      letter-spacing: ${vwTablet(0.5)};
    }
    @media ${media.desktop} {
      line-height: 1.25;
      font-size: ${vwDesktop(56)};
      letter-spacing: ${vwDesktop(0.5)};
    }
  }

  .sc h2, .tc h2 {
    font-family: ${theme.font.ch};
  }

  h3 {
    font-family: ${theme.font.header};
    font-size: ${vwMobile(28)};
    font-weight: normal;
    line-height: 1.3;
    letter-spacing: ${vwMobile(0.25)};
    &.small {
      font-size: ${vwMobile(20)};
      letter-spacing: ${vwMobile(0.18)};
    }
    @media ${media.tablet} {
      font-size: ${vwTablet(40)};
      line-height: 1.25;
      letter-spacing: ${vwTablet(0.36)};
      &.small {
        font-size: ${vwTablet(40)};
        letter-spacing: ${vwTablet(0.36)};
      }
    }
    @media ${media.desktop} {
      font-size: ${vwDesktop(40)};
      line-height: 1.25;
      letter-spacing: ${vwDesktop(0.36)};
      &.small {
        font-size: ${vwDesktop(40)};
        letter-spacing: ${vwDesktop(0.36)};
      }
    }
  }

  .sc h3, .tc h3 {
    font-family: ${theme.font.ch};
  }

  h4 {
    font-family: ${theme.font.family};
    font-weight: 500;
    font-size: ${vwMobile(12)};
    line-height: 1.67;
    letter-spacing: ${vwMobile(1.71)};
    &.callout {
      font-weight: 600;
      font-size: ${vwMobile(10)};
      line-height: 2;
      letter-spacing: ${vwMobile(2)};
    }
    @media ${media.tablet} {
      font-size: ${vwTablet(14)};
      line-height: 1.71;
      letter-spacing: ${vwTablet(2)};
      &.callout {
        font-weight: 500;
        font-size: ${vwTablet(12)};
        line-height: 1.67;
        letter-spacing: ${vwTablet(2)};
      }
    }
    @media ${media.desktop} {
      font-size: ${vwDesktop(14)};
      line-height: 1.71;
      letter-spacing: ${vwDesktop(2)};
      &.callout {
        font-size: ${vwDesktop(12)};
        line-height: 1.67;
        letter-spacing: ${vwDesktop(2)};
      }
    }
  }

  .sc h4 {
    font-family: ${theme.font.sc};
  }

  .tc h4 {
    font-family: ${theme.font.tc};
  }

  .subheader {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.6;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(3)};
    text-transform: uppercase;
    @media ${media.tablet} {
      line-height: 1.67;
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(4)};
    }
    @media ${media.desktop} {
      line-height: 1.67;
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(4)};
    }
  }

  .sc .subheader {
    font-family: ${theme.font.sc};
  }

  .tc .subheader {
    font-family: ${theme.font.tc};
  }

  .menu-action {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.43;
    font-size: ${vwMobile(14)};
    letter-spacing: ${vwMobile(4)};
    text-transform: uppercase;
    @media ${media.tablet} {
      line-height: 7.43;
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(4)};
    }
    @media ${media.desktop} {
      line-height: 7.43;
      font-size: ${vwDesktop(14)};
      letter-spacing: ${vwDesktop(4)};
    }
  }
  
  .sc .menu-action {
    font-family: ${theme.font.sc};
  }

  .tc .menu-action {
    font-family: ${theme.font.tc};
  }

  .menu-cta {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.5;
    font-size: ${vwMobile(12)};
    letter-spacing: ${vwMobile(1.71)};
    text-transform: uppercase;
    @media ${media.tablet} {
      line-height: 1.43;
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
    @media ${media.desktop} {
      line-height: 1.67;
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(1.71)};
    }
  }

  .sc .menu-cta {
    font-family: ${theme.font.sc};
  }

  .tc .menu-cta {
    font-family: ${theme.font.tc};
  }

  .menu-page {
    font-family: ${theme.font.family};
    font-weight: 500;
    line-height: 1.5;
    font-size: ${vwMobile(12)};
    letter-spacing: ${vwMobile(1.71)};
    text-transform: uppercase;
    &.thick {
      font-weight: 600;
    }
    @media ${media.tablet} {
      line-height: 1.43;
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
    @media ${media.desktop} {
      line-height: 1.67;
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(1.71)};
    }
  }

  .sc .menu-page {
    font-family: ${theme.font.sc};
  }

  .tc .menu-page {
    font-family: ${theme.font.tc};
  }

  .menu-language {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.38;
    font-size: ${vwMobile(13)};
    letter-spacing: ${vwMobile(2)};
    text-transform: uppercase;
    @media ${media.tablet} {
      line-height: 1.38;
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(2)};
    }
    @media ${media.desktop} {
      line-height: 1.38;
      font-size: ${vwDesktop(13)};
      letter-spacing: ${vwDesktop(2)};
    }
  }

  .sc .menu-language {
    font-family: ${theme.font.sc};
  }

  .tc .menu-language {
    font-family: ${theme.font.tc};
  }

  .button {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.6;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(1.67)};
    text-transform: uppercase;
    @media ${media.tablet} {
      line-height: 1.5;
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
    }
    @media ${media.desktop} {
      line-height: 1.5;
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
    }
  }

  .sc .button {
    font-family: ${theme.font.sc};
  }

  .tc .button {
    font-family: ${theme.font.tc};
  }

  .form-label {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: 1.33;
    font-size: ${vwMobile(9)};
    letter-spacing: ${vwMobile(2.25)};
    @media ${media.tablet} {
      line-height: 1.4;
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(2.5)};
    }
    @media ${media.desktop} {
      line-height: 1.4;
      font-size: ${vwDesktop(10)};
      letter-spacing: ${vwDesktop(2.5)};
    }
  }

  .sc .form-label {
    font-family: ${theme.font.sc};
  }

  .tc .form-label {
    font-family: ${theme.font.tc};
  }

  .footnote {
    font-family: ${theme.font.family};
    font-weight: 500;
    line-height: 1.6;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(0.36)};
    &.dark {
      letter-spacing: ${vwMobile(0.2)};
    }
    @media ${media.tablet} {
      line-height: 1.6;
      font-size: ${vwTablet(10)};
      letter-spacing: ${vwTablet(0.36)};
      &.dark {
        letter-spacing: ${vwTablet(0.2)};
      }
    }
    @media ${media.desktop} {
      line-height: 1.6;
      font-size: ${vwDesktop(10)};
      letter-spacing: ${vwDesktop(0.36)};
      &.dark {
        letter-spacing: ${vwDesktop(0.2)};
      }
    }
  }

  .sc .footnote {
    font-family: ${theme.font.sc};
  }

  .tc .footnote {
    font-family: ${theme.font.tc};
  }

  p, .form-input {
    font-family: ${theme.font.family};
    font-weight: normal;
    font-size: ${vwMobile(12)};
    letter-spacing: ${vwMobile(0.43)};
    line-height: 1.67;
    &.list {
      line-height: 2;
    }
    &.p2 {
      letter-spacing: ${vwMobile(0.36)};
      line-height: 1.8;
    }
    &.p2-menu {
      font-size: ${vwMobile(8)};
      letter-spacing: ${vwMobile(0.29)};
      line-height: 1.75;
    }
    &.callout {
      font-size: ${vwMobile(11)};
      font-weight: 500;
      line-height: 1.64;
      letter-spacing: ${vwMobile(0.39)};
      opacity: 0.9;
    }
    span.small {
      font-style:italic;
      font-weight: normal;
    }
    @media ${media.tablet} {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(0.5)};
      line-height: 1.71;
      &.p2-menu {
        font-size: ${vwTablet(12)};
        letter-spacing: ${vwMobile(0.43)};
        line-height: 1.67;
      }
      &.callout {
        font-size: ${vwTablet(12)};
        font-weight: normal;
        line-height: 1.67;
        letter-spacing: ${vwTablet(0.5)};
        opacity: 1;
      }
    }
    @media ${media.desktop} {
      font-size: ${vwDesktop(14)};
      letter-spacing: ${vwDesktop(0.5)};
      line-height: 1.71;
      &.list {
        line-height: 2;
      }
      &.p2 {
        letter-spacing: ${vwDesktop(0.43)};
        line-height: 1.67;
      }
      &.p2-menu {
        font-size: ${vwDesktop(14)};
        letter-spacing: ${vwDesktop(0.43)};
        line-height: 1.67;
      }
      &.callout {
        font-size: ${vwDesktop(12)};
        line-height: 1.67;
        letter-spacing: ${vwDesktop(0.5)};
      }
      span.small {
        font-size: ${vwDesktop(10)};
        line-height: 1.6;
        letter-spacing: ${vwDesktop(0.2)};
        font-style: normal;
      }
    }
  }

  .sc p, .sc .form-input {
    font-family: ${theme.font.sc};
  }

  .tc p, .tc .form-input {
    font-family: ${theme.font.tc};
  }
 .tc h1 span, 
 .tc h2 span,
 .tc h3 span,
 .tc h4 span, 
 .sc h1 span, 
 .sc h2 span,
 .sc h3 span,
 .sc h4 span{ font-family:'Ogg-Roman';font-style:italic;}
`;
