import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TwentyTwenty from 'react-twentytwenty';

import Intl from 'components/Intl';
import FadeUp from 'components/animations/FadeUp';

import media from 'src/styles/media';
import { vwMobile, vwDesktop, vwTablet } from 'src/styles/utils';

const CompareCaption = (props) => {
  const { images, height, title, text, fullWidth } = props;

  const [activeImage, setActiveImage] = useState(0);

  const handleImage = () => {
    if (activeImage === 0) {
      setActiveImage(1);
    } else {
      setActiveImage(0);
    }
  };

  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root fullWidth={fullWidth}>
            <Mobile onClick={handleImage}>
              {images.map((img, index) => (
                <img
                  src={img}
                  alt=""
                  key={index}
                  style={
                    index !== 0
                      ? { opacity: activeImage === index ? 1 : 0 }
                      : {}
                  }
                />
              ))}
            </Mobile>
            <Desktop height={height} fullWidth={fullWidth}>
              <TwentyTwenty
                left={<img src={images[0]} alt="" />}
                right={<img src={images[1]} alt="" />}
                slider={
                  <Slider>
                    <div>
                      <img
                        src={require('src/assets/images/icons/icon-chevron-light.svg')}
                        alt="slider"
                      />
                      <img
                        src={require('src/assets/images/icons/icon-chevron-light.svg')}
                        alt="slider"
                      />
                    </div>
                  </Slider>
                }
              />
            </Desktop>
            <Caption fullWidth={fullWidth}>
              {title && <h4 className="dark callout">{title}</h4>}
              <p className="p2 dark callout">{text}</p>
              <p className="p2 toggle dark">
                {t.design.imageCompares.calloutTap}
              </p>
            </Caption>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

CompareCaption.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
};

const Root = styled.div`
  padding: ${(props) => (props.fullWidth ? 0 : `0 ${vwMobile(16)}`)};
  margin-bottom: ${vwMobile(40)};
  h4 {
    text-transform: uppercase;
  }
  @media ${media.tablet} {
    padding: ${(props) => (props.fullWidth ? 0 : `0 ${vwTablet(40)}`)};
    margin-bottom: ${vwTablet(80)};
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: ${(props) => (props.fullWidth ? 'column' : 'row')};
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 ${vwDesktop(60)};
    margin-bottom: ${vwDesktop(160)};
  }
`;

const Mobile = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    transition: 0.3s ease;
  }
  img:last-of-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    box-shadow: ${({ theme }) => theme.shadow};
    width: ${(props) => (props.fullWidth ? '100%' : '74.6%')};
    height: ${(props) => vwDesktop(props.height)};
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    div {
      height: 100%;
    }
  }
`;

const Caption = styled.div`
  padding: ${(props) => (props.fullWidth ? `0 ${vwMobile(16)}` : 0)};
  margin-top: ${vwMobile(12)};
  .toggle {
    opacity: 0.9;
    font-style: italic;
    font-size: ${vwMobile(10)};
    line-height: 1.8;
    letter-spacing: ${vwMobile(0.36)};
    color: ${({ theme }) => theme.color.darkBrown};
  }
  p {
    margin: ${vwMobile(4)} 0;
    span {
      font-weight: 600;
    }
  }
  @media ${media.tablet} {
    padding: 0;
    margin: ${(props) => (props.fullWidth ? `0 ${vwTablet(40)}` : 0)};
    width: 100%;
    margin-top: ${vwTablet(24)};
    max-width: ${vwTablet(450)};
    p,
    .toggle {
      font-size: ${vwTablet(12)};
      line-height: 1.67;
      letter-spacing: ${vwTablet(0.5)};
    }
    p {
      margin: ${vwTablet(4)} 0;
    }
  }
  @media ${media.desktop} {
    margin: 0;
    margin-top: ${(props) => (props.fullWidth ? vwDesktop(24) : 0)};
    width: ${(props) => (props.fullWidth ? '50%' : 'auto')};
    .toggle {
      display: none;
    }
    p {
      font-size: ${vwDesktop(12)};
      line-height: 1.67;
      letter-spacing: ${vwDesktop(0.5)};
      margin: ${vwDesktop(4)} 0 0;
      max-width: ${(props) =>
        props.fullWidth ? vwDesktop(400) : vwDesktop(286)};
    }
  }
`;

const Slider = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.darkBrown};
  cursor: pointer;
  div {
    width: ${vwDesktop(48)};
    height: ${vwDesktop(48)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.darkBrown};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${vwDesktop(10)};
  }
  img {
    width: ${vwDesktop(7)} !important;
    height: auto;
    display: block;
    &:last-of-type {
      transform: scaleX(-1);
    }
  }
`;

export default CompareCaption;
