import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import TitleBlock from 'components/TitleBlock';
import ContentBlock from 'components/ContentBlock';
import FullImage from 'components/FullImage';
import LinkBlock from 'components/LinkBlock';
import Building from './elements/Building';
import Collage from './elements/Collage';
import Atelier from './sections/Atelier';
import Images from './sections/Images';

import vw from 'src/styles/utils';

const Creation = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page removeOverflow>
              <Hero
                subheader={t.creation.subheader}
                header={t.creation.header}
                video="https://player.vimeo.com/external/492220072.hd.mp4?s=ec45b092f07eb7e355cdad6ae205abcc57760c24&amp;profile_id=174"
                popupVideo="https://player.vimeo.com/external/510414219.hd.mp4?s=97b4113b095c2cda061af9082c26a7ca7ec0f073&amp;profile_id=175"
              />
              <Space />
              <ContentBlock
                title={t.creation.contentBlock[0].title}
                body={t.creation.contentBlock[0].body}
                images={t.creation.contentBlock[0].images}
                settings={t.creation.contentBlock[0].settings}
              />
              <TitleBlock
                subTitle={t.creation.atelier.subTitle}
                title={t.creation.atelier.title}
              />
              <Atelier />
              <ContentBlock
                subtitle={t.creation.contentBlock[1].subtitle}
                title={t.creation.contentBlock[1].title}
                body={t.creation.contentBlock[1].body}
                images={t.creation.contentBlock[1].images}
                settings={t.creation.contentBlock[1].settings}
                leftPadding
              />
              <Building />
              <Collage />
              <Space />
              <ContentBlock
                subtitle={t.creation.contentBlock[2].subtitle}
                title={t.creation.contentBlock[2].title}
                body={t.creation.contentBlock[2].body}
                images={t.creation.contentBlock[2].images}
                settings={t.creation.contentBlock[2].settings}
              />
              <Images />
              <FullImage
                image={require('src/assets/images/creation/gryphon-house-kitchen-living.jpg')}
                alt="Gryphon House"
              />
              <Space />
              <LinkBlock
                title={t.creation.linkBlock.title}
                body={t.creation.linkBlock.text}
                buttonLabel={t.creation.linkBlock.buttonLabel}
                bgImageMobile="creation/arbutus-greenway-rendering.jpeg"
                bgImageDesktop="creation/arbutus-greenway-rendering.jpeg"
                url="/location"
                noLogo
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Creation;

const Root = styled.div``;

const HeroImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 741.2, 1234)}
`;

const Space = styled.div`
  ${vw('margin-top', 80, 160)}
`;
