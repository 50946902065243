import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { useSelector } from 'react-redux';

import Intl from 'components/Intl';
import FadeUp from 'components/animations/FadeUp';
import ParallaxImage from 'components/animations/ParallaxImage';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Collage = () => {
  const browser = useSelector((state) => state.browser);
  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <Images>
              <Parallax y={[10, -10]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/creation/gryphon-house-storefront.jpg')}
                  alt="Gryphon House Storefront"
                  className="storefront"
                />
              </Parallax>
              <Parallax y={[20, -20]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/creation/gryphon-house-sidewalk.jpg')}
                  alt="Gryphon House Sidewalk"
                  className="sidewalk"
                />
              </Parallax>
              <Parallax y={[10, -10]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/creation/gryphon-house-exterior-entrance.jpg')}
                  alt="Gryphon House Exterior"
                  className="exterior"
                />
              </Parallax>
            </Images>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)};
  position: relative;
  @media ${media.tablet} {
    padding: 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    padding: 0;
    margin: 0 ${vwDesktop(60)} ${vwDesktop(160)};
    width: ${vwDesktop(1220)};
    height: ${vwDesktop(1680)};
    position: relative;
  }
`;

const Images = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    img {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    img {
      margin-bottom: 0;
      width: 100%;
      height: 100%;
    }
    .parallax-outer {
      position: absolute;
    }
    .parallax-outer:nth-of-type(1) {
      width: ${vwDesktop(700)};
      height: ${vwDesktop(712)};
      top: 0;
      left: 0;
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
    .parallax-outer:nth-of-type(2) {
      width: ${vwDesktop(391)};
      height: ${vwDesktop(560)};
      top: ${vwDesktop(540)};
      right: 0;
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
    .parallax-outer:nth-of-type(3) {
      width: ${vwDesktop(495)};
      height: ${vwDesktop(720)};
      bottom: 0;
      left: ${vwDesktop(103)};
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
  }
`;

export default Collage;
