import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import Intl from 'components/Intl';
import RoundButton from 'components/typography/RoundButton';
import FadeUp from 'components/animations/FadeUp';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Plans = () => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleFilter = (index) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveFilter(index);
      setIsTransitioning(false);
    }, 400);
  };

  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <FadeUp>
            <h3 className="subheader moss">{t.residences.plans.title}</h3>
            <Container>
              <div>
                {t.residences.plans.filters.map((item, index) => (
                  <Button
                    key={item.text}
                    onClick={() => handleFilter(index)}
                    active={activeFilter === index}
                    className={locale}
                  >
                    <span className="mobile">{item.mobile}</span>
                    <span className="desktop">{item.text}</span>
                  </Button>
                ))}
              </div>
              <Features
                href="/assets/pdf/sample_floorplans.pdf"
                target="_blank"
                desktop
              >
                <RoundButton
                  handleClick={(f) => f}
                  transparent
                  text={t.residences.plans.button}
                />
              </Features>
            </Container>
          </FadeUp>
          <Images isTransitioning={isTransitioning}>
            {t.residences.plans.filters[activeFilter].plans.map(
              (plan, index) => (
                <FadeUp>
                  <img
                    src={require(`src/assets/images/residences/sample-plans/plan-${plan}-mobile-${locale}.png`)}
                    alt={`Plan ${plan}`}
                    className="mobile"
                  />
                  <img
                    src={require(`src/assets/images/residences/sample-plans/plan-${plan}-desktop-${locale}.png`)}
                    alt={`Plan ${plan}`}
                    className="desktop"
                  />
                </FadeUp>
              )
            )}
          </Images>
          <Features
            href="/assets/pdf/sample_floorplans.pdf"
            target="_blank"
            mobile
          >
            <RoundButton
              handleClick={(f) => f}
              transparent
              text={t.residences.plans.button}
            />
          </Features>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin: ${vwMobile(80)} ${vwMobile(16)};
  h3 {
    margin-bottom: ${vwMobile(12)};
  }
  @media ${media.tablet} {
    margin: ${vwTablet(160)} ${vwTablet(40)};
    h3 {
      margin-bottom: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(160)} ${vwDesktop(60)} ${vwDesktop(160)};
    h3 {
      margin-bottom: ${vwDesktop(20)};
    }
  }
`;

const Button = styled.button`
  padding: ${vwMobile(7)} ${vwMobile(8)};
  border-radius: ${vwMobile(18)};
  font-size: ${vwMobile(9)};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: ${vwMobile(1)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.darkBrown};
  background-color: ${({ theme }) => theme.color.taupe};
  margin-right: ${vwMobile(8)};
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  transition: ${({ theme }) =>
    `${theme.animation.timingFunction} ${theme.animation.time}`};
  &.tc {
    font-family: ${({ theme }) => theme.font.tc};
  }
  &:last-of-type {
    margin-right: 0;
  }
  .desktop {
    display: none;
  }
  @media ${media.tablet} {
    padding: ${vwTablet(11)} ${vwTablet(24)};
    border-radius: ${vwTablet(18)};
    font-size: ${vwTablet(10)};
    line-height: 1.4;
    letter-spacing: ${vwTablet(2.5)};
    margin-right: ${vwTablet(12)};
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(11)} ${vwDesktop(24)};
    border-radius: ${vwDesktop(18)};
    font-size: ${vwDesktop(10)};
    line-height: 1.4;
    letter-spacing: ${vwDesktop(2.5)};
    margin-right: ${vwDesktop(12)};
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const FadeInCss = css`
  animation: ${FadeIn}
    ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`}
    forwards;
`;

const FadeOutCss = css`
  animation: ${FadeOut}
    ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`}
    forwards;
`;

const Images = styled.div`
  margin: ${vwMobile(40)} 0;
  opacity: 0;
  ${(props) => (props.isTransitioning ? FadeOutCss : FadeInCss)}
  .desktop {
    display: none;
  }
  img {
    display: block;
    margin-bottom: ${vwMobile(60)};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media ${media.tablet} {
    margin: ${vwTablet(40)} 0 ${vwTablet(80)};
    img {
      display: block;
      margin-bottom: ${vwTablet(120)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media ${media.desktop} {
    img {
      display: block;
      margin-bottom: ${vwDesktop(120)};
      width: 100%;
      height: ${vwDesktop(680)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`;

const Features = styled.a`
  display: ${(props) => (props.desktop ? 'none' : 'block')};
  margin: 0 auto;
  width: fit-content;
  @media ${media.desktop} {
    display: ${(props) => (props.desktop ? 'block' : 'none')};
    margin: 0;
  }
`;

const Container = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export default Plans;
