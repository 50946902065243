import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/Hero';
import TitleBlock from 'components/TitleBlock';
import ImageCompare from 'components/layout/ImageCompare';
import ImageCallout from 'components/layout/ImageCallout';
import RoundButton from 'components/typography/RoundButton';
import LinkBlock from 'components/LinkBlock';
import Plans from './sections/Plans';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Residences = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Root>
            <Hero
              subheader={t.residences.hero.subTitle}
              header={t.residences.hero.title}
              video="https://player.vimeo.com/external/492223695.hd.mp4?s=53479f0e383fad83fc83bd5ed15dfb18a12c87a6&amp;profile_id=174"
            />
            <Plans />
            <TitleBlock
              subTitle={t.residences.kitchen.subTitle}
              title={t.residences.kitchen.title}
              text={t.residences.kitchen.text}
            />
            <ImageCompare
              left={require('src/assets/images/residences/kitchen-scheme-dark.jpeg')}
              right={require('src/assets/images/residences/kitchen-scheme-light.jpeg')}
            />
            <TitleBlock
              subTitle={t.residences.ensuites.subTitle}
              title={t.residences.ensuites.title}
              text={t.residences.ensuites.text}
            />
            {/* <ImageCompare
              left={require('src/assets/images/residences/bathroom-scheme-dark.jpeg')}
              right={require('src/assets/images/residences/bathroom-scheme-light.jpeg')}
              tall
            /> */}
            <Wrapper>
              <ImageCallout
                image={require('src/assets/images/residences/bathroom-scheme-dark.jpeg')}
                parallax={[5, -5]}
                width={1220}
              />
            </Wrapper>
            <Features href="/assets/pdf/feature_list.pdf" target="_blank">
              <RoundButton
                handleClick={(f) => f}
                transparent
                text={t.residences.features}
              />
            </Features>
            <LinkBlock
              title={t.residences.linkBlock.title}
              body={t.residences.linkBlock.text}
              buttonLabel={t.residences.linkBlock.button}
              bgImageMobile="amenities/gryphon-house-club-lounge.jpeg"
              bgImageDesktop="amenities/gryphon-house-club-lounge.jpeg"
              url="/amenities"
              noLogo
              small
            />
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.div`
  @media ${media.tablet} {
    .mobile-space {
      display: none;
    }
  }
`;

// const HeroImage = styled.img`
//   position: absolute;
//   top: 0;
//   width: auto;
//   height: ${vwMobile(438)};
//   top: ${vwMobile(40)};
//   left: 50%;
//   transform: translateX(-50%);
//   @media ${media.tablet} {
//     height: ${vwTablet(438)};
//     top: ${vwTablet(40)};
//   }
//   @media ${media.desktop} {
//     width: ${vwDesktop(806)};
//     height: ${vwDesktop(723)};
//     object-fit: contain;
//     left: ${vwDesktop(303)};
//     transform: none;
//   }
// `;

const Wrapper = styled.div`
  margin: ${vwMobile(40)} 0 ${vwMobile(80)};
  @media ${media.tablet} {
    margin: ${vwTablet(80)} 0 ${vwTablet(80)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(80)} 0 ${vwDesktop(80)};
    padding: 0 ${vwDesktop(60)};
  }
`;

const Features = styled.a`
  display: block;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: ${vwMobile(80)};
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(160)};
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(160)};
  }
`;

export default Residences;
