import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const VideoButton = (props) => {
  const { vertical, handleClick, text, disabled } = props;
  return (
    <Root vertical={vertical} onClick={handleClick} disabled={disabled}>
      <img
        src={require('src/assets/images/icons/icon-play-purple.png')}
        alt="Play video"
      />
      <p className="button">{text}</p>
    </Root>
  );
};

VideoButton.propTypes = {
  vertical: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  text: PropTypes.string,
};

const Root = styled.button`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  color: ${({ theme }) => theme.color.purple};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  img {
    width: ${vwMobile(24)};
    height: auto;
    display: block;
    object-fit: contain;
  }
  p {
    margin-left: ${(props) => (props.vertical ? 0 : vwMobile(6))};
    margin-top: ${(props) => (props.vertical ? vwMobile(6) : 0)};
    white-space: nowrap;
  }
  @media ${media.tablet} {
    img {
      width: ${vwTablet(24)};
      height: auto;
    }
    p {
      margin-left: ${(props) => (props.vertical ? 0 : vwTablet(6))};
      margin-top: ${(props) => (props.vertical ? vwTablet(6) : 0)};
    }
  }
  @media ${media.desktop} {
    img {
      width: ${vwDesktop(28)};
      height: auto;
    }
    p {
      margin-left: ${(props) => (props.vertical ? 0 : vwDesktop(12))};
      margin-top: ${(props) => (props.vertical ? vwDesktop(12) : 0)};
    }
  }
`;

export default VideoButton;
