export default [
  {
    text: 'philosophy',
    to: '/philosophy',
    image: require('src/assets/images/menu/hero-artist-in-grand-hall.jpeg'),
  },
  {
    text: 'creation',
    to: '/creation',
    image: require('src/assets/images/menu/hero-painting-on-canvas.jpg'),
  },
  {
    text: 'LOCATION',
    to: '/location',
    image: require('src/assets/images/menu/hero-kerrisdale-aerial.jpeg'),
  },
  {
    text: 'Design',
    to: '/design',
    image: require('src/assets/images/menu/hero-design-interiors.jpg'),
  },
  {
    text: 'plans',
    to: '/plans',
    image: require('src/assets/images/menu/hero-bedroom-interiors.jpg'),
    notVisible: true,
  },
  {
    text: 'amenities',
    to: '/amenities',
    image: require('src/assets/images/menu/hero-artist-in-grand-dining.jpg'),
  },
  {
    text: 'contact',
    to: '/contact',
    image: require('src/assets/images/menu/hero-lobby-concierge.jpg'),
  },
  {
    text: 'register',
    to: '/register',
    image: require('src/assets/images/menu/hero-interior-kitchen.jpg'),
    register: true,
  },
];
