// Constants
export const SET__ISOPEN = 'MENU::SET';

// Actions
export const setIsOpen = (bool) => ({
  type: SET__ISOPEN,
  bool,
});

// Initial State
const initialState = {
  isOpen: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET__ISOPEN:
      return {
        ...state,
        isOpen: action.bool,
      };
    default:
      return state;
  }
};
