import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import TitleBlock from 'components/TitleBlock';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Curator = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <TitleBlock
            subTitle={t.amenities.titleBlock.subtitle}
            title={t.amenities.titleBlock.title}
          />
          <Watermark
            src={require('src/assets/images/amenities/royal-curator-watermark.png')}
            alt="Watermark"
          />
          <Logo
            src={require('src/assets/images/logos/royal-curator-logo.png')}
            alt="Royal Curator"
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
`;

const Watermark = styled.img`
  display: block;
  width: auto;
  height: ${vwMobile(267)};
  position: absolute;
  top: 60%;
  right: -25%;
  transform: translateY(-50%);
  @media ${media.tablet} {
    height: ${vwTablet(400)};
    right: -10%;
  }
  @media ${media.desktop} {
    height: ${vwDesktop(400)};
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
`;

const Logo = styled.img`
  width: auto;
  height: ${vwMobile(72)};
  display: block;
  margin: ${vwMobile(120)} auto 0;
  @media ${media.tablet} {
    height: ${vwTablet(72)};
    margin: ${vwTablet(120)} auto 0;
  }
  @media ${media.desktop} {
    height: ${vwDesktop(100)};
    margin: ${vwDesktop(160)} auto 0;
  }
`;

export default Curator;
