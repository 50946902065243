import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Lines from 'components/Lines';
import GradientButton from 'components/typography/GradientButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Register = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Lines light mobile />
          <Wrapper>
            <h4 className="subheader light">{t.footer.register.subheader}</h4>
            <h2 className="light mobile">{t.footer.register.header}</h2>
            <h3 className="light desktop">{t.footer.register.header}</h3>
            <GradientButton
              text={t.footer.register.button}
              to={`${getLocaleURL()}/register`}
              background="#612a45"
            />
          </Wrapper>
          <Lines light mobile />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.purple};
  padding: ${vwMobile(32)} ${vwMobile(16)};
  position: relative;
  text-align: center;
  h3 span,
  h2 span {
    font-style: italic;
  }
  h2 {
    padding: ${vwMobile(20)} 0 ${vwMobile(32)};
  }
  .desktop {
    display: none;
  }
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(40)};
    h2,
    h3 {
      padding: ${vwTablet(40)} 0;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)};
    br {
      display: none;
    }
    h2,
    h3 {
      padding: ${vwDesktop(40)} 0;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vwMobile(60)} 0;
  @media ${media.tablet} {
    padding: ${vwTablet(60)} 0;
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(100)} 0;
  }
`;

export default Register;
