import appConfig from 'src/config/app.conf';
const { stage } = appConfig;

export default (property, wMobile, wTablet, wDesktop) => {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  return `${property}: ${vwMobile(wMobile)};
    @media (min-width: ${appConfig.mediaQuery.tablet}px) {
      ${property}: ${vwTablet(wTablet)};
    }
    @media (min-width: ${appConfig.mediaQuery.desktop}px) {
      ${property}: ${vwDesktop(wDesktop)};
    }
  `;
};

export const vwMobile = (width) => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = (width) => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = (width) => {
  return `${(width / stage.desktop) * 100}vw`;
};
