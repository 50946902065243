import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Logo = (props) => {
  const { active, fadeLogo } = props;
  return (
    <Root
      src={require('src/assets/images/logos/gryphonhouse-logo-gold.png')}
      alt="Gryphon house"
      key={active}
      fadeLogo={fadeLogo}
    />
  );
};

const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInCss = css`
  animation-name: ${FadeIn};
  animation-duration: ${({ theme }) => theme.animation.time};
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

const Root = styled.img`
  width: ${vwMobile(67)};
  display: block;
  opacity: ${props => props.fadeLogo ? 0 : 1};
  ${props => props.fadeLogo ? FadeInCss : ''}
  @media ${media.tablet} {
    width: ${vwTablet(100)};
  }
  @media ${media.desktop} {
    margin: 0 ${vwDesktop(40)};
    width: ${vwDesktop(100)};
    height: ${vwDesktop(149.33)};
  }
`;

export default Logo;
