import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import ImageBlock from '../elements/ImageBlock';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Atelier = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Logo
            src={require('src/assets/images/logos/logo-atelier-dark.png')}
            alt="Atelier"
          />
          <ImageBlock
            image={require('src/assets/images/creation/illustration-building-southeast.png')}
            title={t.creation.atelier.block1.title}
            text={t.creation.atelier.block1.text}
          />
          <ImageBlock
            image={require('src/assets/images/creation/illustration-interior-design.png')}
            title={t.creation.atelier.block2.title}
            text={t.creation.atelier.block2.text}
            right
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin: ${vwMobile(120)} 0;
  @media ${media.tablet} {
    margin: ${vwTablet(160)} 0 ${vwTablet(240)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(160)} 0 ${vwDesktop(320)};
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vwMobile(144)};
  margin: 0 auto ${vwMobile(120)};
  @media ${media.tablet} {
    width: ${vwTablet(245)};
    margin: 0 auto ${vwTablet(160)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(245)};
    margin: 0 auto ${vwDesktop(160)};
  }
`;

export default Atelier;
