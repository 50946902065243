import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import ParallaxImage from 'components/animations/ParallaxImage';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ImageBlock = (props) => {
  const { image, title, text, right } = props;
  return (
    <Root right={right}>
      <Parallax y={[20, -60]}>
        <ParallaxImage src={image} alt="" />
      </Parallax>
      <div>
        <h3 className="small">{title}</h3>
        <p>{text}</p>
      </div>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  padding: 0 ${vwMobile(16)};
  text-align: center;
  margin-bottom: ${vwMobile(80)};
  h3 {
    margin-bottom: ${vwMobile(12)};
  }
  p {
    max-width: ${vwMobile(260)};
    margin: 0 auto;
  }
  img {
    display: block;
    width: auto;
    height: ${(props) => (props.right ? vwMobile(287) : vwMobile(220))};
  }
  .parallax-outer {
    position: absolute;
    top: ${(props) => (props.right ? vwMobile(-40) : vwMobile(-140))};
    left: ${(props) => (props.right ? 'auto' : '-50%')};
    right: ${(props) => (props.right ? '-20%' : 'auto')};
    width: auto;
    height: ${(props) => (props.right ? vwMobile(287) : vwMobile(220))};
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    margin-bottom: ${vwTablet(160)};
    h3 {
      margin-bottom: ${vwTablet(40)};
    }
    p {
      max-width: ${vwTablet(451)};
    }
    img {
      height: ${(props) => (props.right ? vwTablet(570) : vwTablet(400))};
    }
    .parallax-outer {
      top: ${(props) => (props.right ? vwTablet(-100) : vwTablet(-170))};
      left: ${(props) => (props.right ? 'auto' : '-30%')};
      right: ${(props) => (props.right ? '-20%' : 'auto')};
      height: ${(props) => (props.right ? vwTablet(570) : vwTablet(400))};
    }
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(163)};
    margin-bottom: 0;
    text-align: left;
    display: flex;
    justify-content: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
    h3 {
      margin-bottom: ${vwDesktop(40)};
      max-width: ${vwDesktop(249)};
    }
    p {
      max-width: ${vwDesktop(391)};
      margin: 0;
    }
    img {
      height: ${(props) => (props.right ? vwDesktop(620) : vwDesktop(510))};
    }
    .parallax-outer {
      top: ${(props) => (props.right ? vwDesktop(-100) : vwDesktop(-170))};
      left: ${(props) => (props.right ? 'auto' : '-20%')};
      right: ${(props) => (props.right ? 0 : 'auto')};
      height: ${(props) => (props.right ? vwDesktop(570) : vwDesktop(461))};
    }
  }
`;

export default ImageBlock;
