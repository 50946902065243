import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'components/Intl';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const DesktopClose = (props) => {
  const { active, handleClose } = props;

  const handleLocaleClick = (e) => {
    e.stopPropagation();
    handleClose();
  };

  return (
    <Intl>
      {(t, { locale }) => (
        <Root
          style={{
            opacity: active ? 1 : 0,
            pointerEvents: active ? 'all' : 'none',
          }}
          onClick={handleClose}
        >
          <div className="pseudo" />
          <p className="menu-action dark">close</p>
          <div>
            <LocaleLink
              to="/en"
              className="menu-language dark"
              active={locale === 'en'}
              onClick={(e) => handleLocaleClick(e)}
            >
              En
            </LocaleLink>
            <LocaleLink
              to="/"
              className="menu-language dark"
              active={locale === 'tc'}
              onClick={(e) => handleLocaleClick(e)}
            >
              繁
            </LocaleLink>
          </div>
        </Root>
      )}
    </Intl>
  );
};

DesktopClose.propTypes = {
  handleClose: PropTypes.func,
  active: PropTypes.bool,
};

const Root = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    height: 100vh;
    width: ${vwDesktop(100)};
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${vwDesktop(40)} 0;
    cursor: pointer;
    z-index: 101;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a {
      display: block;
      margin-bottom: ${vwDesktop(16)};
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .pseudo {
      height: ${vwDesktop(83)};
    }
  }
`;

const LocaleLink = styled(Link)`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  &:hover {
    opacity: 1;
  }
`;

export default DesktopClose;
