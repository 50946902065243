import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const small = css`
  ${vw('width', 98, 123)}
`;

const medium = css`
  ${vw('width', 114, 143)}
`;

const large = css`
  ${vw('width', 143, 179)}
`;

const logoSizeArray = [small, medium, large]

const Card = (props) => {
  const { logo, logoSize, body, background } = props;
  return (
    <Root src={require(`src/assets/images/philosophy/${background}`)}>
      <Overlay />
      <Content>
        <Logo logoSize={logoSizeArray[logoSize]}>
          <img src={require(`src/assets/images/logos/${logo}`)} alt ='' />
        </Logo>
        <p className='light'>{body}</p>
      </Content>
    </Root>
  )
}

export default Card;

Card.propTypes = {
  logo: PropTypes.string,
  body: PropTypes.string,
  backround: PropTypes.string,
}

const Root = styled.div`
  position: relative;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;

  ${vw('width', 288, 688, 1012)}
  ${vw('height', 300, 200)}
  ${vw('padding-right', 20, 32, 102)}
  ${vw('padding-left', 20, 0)}
  ${vw('margin-bottom', 20)}

  @media ${media.tablet} {
    background-position: 0 60%;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;

  @media ${media.tablet} {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${vw('padding-top', 48, 0)}

  p {
    text-align: center;
    ${vw('margin-left', 0, 45, 280)};
    ${vw('width', 248, 387, 390)}
  }

  @media ${media.tablet} {
    flex-direction: row;
    justify-content: flex-start;
    p {
      text-align: left;
    }
  }
`;

const Logo = styled.div`
  position: relative;
  text-align: center;
  ${vw('width', 143, 179)}
  ${vw('margin-bottom', 40, 0)}
  
  img {
    ${props => props.logoSize}
  }

  @media ${media.desktop} {
    margin-left: ${vwDesktop(40)}
  }
`;

