import React from 'react';
import styled from 'styled-components';
import { Parallax, withController } from "react-scroll-parallax";
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Illustration = (props) => {
  const { mobile, tablet, desktop, distance, image, parallaxController } = props;

  const handleLoad = () => {
    parallaxController.update();
  }
  return (
    <Root mobile={mobile} tablet={tablet} desktop={desktop}>
      <Parallax y={[0, distance]}>
        <img src={require(`src/assets/images/${image}`)} alt='' onLoad={handleLoad} />
      </Parallax>
    </Root>
  )
}

export default withController(Illustration);

Illustration.propTypes = {
  mobile: PropTypes.object,
  tablet: PropTypes.object,
  desktop: PropTypes.object,
  distance: PropTypes.number,
  image: PropTypes.string
}

const Root = styled.div`
  pointer-events: none;
  position: absolute;
  ${props => vw('width', props.mobile.width, props.tablet.width, props.desktop.width)}
  ${props => vw('height', props.mobile.height, props.tablet.height, props.desktop.height)}
  ${props => vw('top', props.mobile.top, props.tablet.top, props.desktop.top)}
  ${props => vw('left', props.mobile.left, props.tablet.left, props.desktop.left)}
`;