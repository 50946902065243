import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { useSelector } from 'react-redux';

import Intl from 'components/Intl';
import FadeUp from 'components/animations/FadeUp';
import ParallaxImage from 'components/animations/ParallaxImage';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Collage = () => {
  const browser = useSelector((state) => state.browser);
  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <Content>
              <h3 className="dark small">{t.design.collage.title}</h3>
            </Content>
            <Images>
              <Parallax y={[5, -5]} disabled={!browser.is.desktop}>
                <Background
                  src={require('src/assets/images/general/limestone-background.jpg')}
                  alt="Background"
                />
              </Parallax>
              <Parallax y={[10, -10]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/design/gryphon-house-east-view-closeup.jpg')}
                  alt="Gryphon House Storefront"
                  className="storefront"
                />
              </Parallax>
              <Parallax y={[20, -20]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/creation/gryphon-house-sidewalk.jpg')}
                  alt="Gryphon House Sidewalk"
                  className="sidewalk"
                />
              </Parallax>
              <Parallax y={[10, -10]} disabled={!browser.is.desktop}>
                <ParallaxImage
                  src={require('src/assets/images/design/gryphon-house-exterior-entrance-closeup.jpg')}
                  alt="Gryphon House Exterior"
                  className="exterior"
                />
              </Parallax>
            </Images>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)};
  margin: ${vwMobile(80)} 0;
  position: relative;
  @media ${media.tablet} {
    padding: 0 ${vwTablet(40)};
    margin: ${vwTablet(140)} 0 ${vwTablet(160)};
  }
  @media ${media.desktop} {
    padding: 0;
    margin: ${vwDesktop(136)} ${vwDesktop(60)} ${vwDesktop(160)};
    width: ${vwDesktop(1220)};
    height: ${vwDesktop(1311)};
    position: relative;
  }
`;

const Content = styled.div`
  padding-top: ${vwMobile(80)};
  h2 span,
  h3 span {
    font-style: italic;
  }
  p {
    margin: ${vwMobile(20)} 0 ${vwMobile(40)};
  }
  h3 {
    margin-bottom: ${vwMobile(40)};
  }
  @media ${media.tablet} {
    padding-top: ${vwTablet(80)};
    max-width: ${vwTablet(474)};
    p {
      margin: ${vwTablet(40)} 0 ${vwTablet(80)};
      max-width: ${vwTablet(450)};
    }
    h3 {
      font-size: ${vwTablet(32)};
      letter-spacing: ${vwTablet(0.29)};
      line-height: 1.44;
      margin-bottom: ${vwTablet(80)};
    }
  }
  @media ${media.desktop} {
    padding-top: 0;
    max-width: ${vwDesktop(474)};
    position: absolute;
    right: ${vwDesktop(45)};
    top: ${vwDesktop(211)};
    z-index: 2;
    p {
      margin: ${vwDesktop(40)} 0 0;
      max-width: none;
    }
    h3 {
      font-size: ${vwDesktop(32)};
      letter-spacing: ${vwDesktop(0.29)};
      line-height: 1.44;
      margin-bottom: ${vwDesktop(80)};
    }
  }
`;

const Images = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    img {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    img {
      margin-bottom: 0;
      width: 100%;
      height: 100%;
    }
    .parallax-outer {
      position: absolute;
    }
    .parallax-outer:nth-of-type(1) {
      width: ${vwDesktop(802)};
      height: ${vwDesktop(1031)};
      position: absolute;
      left: 50%;
      top: ${vwDesktop(140)};
      transform: translateX(-50%);
    }
    .parallax-outer:nth-of-type(2) {
      width: ${vwDesktop(495)};
      height: ${vwDesktop(503)};
      top: 0;
      left: ${vwDesktop(103)};
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
    .parallax-outer:nth-of-type(3) {
      width: ${vwDesktop(390)};
      height: ${vwDesktop(560)};
      top: ${vwDesktop(483)};
      right: ${vwDesktop(104)};
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
    .parallax-outer:nth-of-type(4) {
      width: ${vwDesktop(440)};
      height: ${vwDesktop(560)};
      top: ${vwDesktop(741)};
      left: ${vwDesktop(182)};
      .parallax-inner {
        box-shadow: ${({ theme }) => theme.shadow};
      }
    }
  }
`;

const Background = styled(ParallaxImage)`
  width: 100%;
  height: ${vwMobile(680)} !important;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
  @media ${media.tablet} {
    height: ${vwTablet(987)} !important;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(700)} !important;
    height: ${vwDesktop(900)} !important;
    /* position: absolute;
    left: 50%;
    top: ${vwDesktop(368)};
    transform: translateX(-50%); */
    opacity: 0.8;
  }
`;

export default Collage;
