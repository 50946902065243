import React, { useState } from 'react';
import styled from 'styled-components';

import FadeUp from 'components/animations/FadeUp';
import Intl from 'components/Intl';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import mapImages from 'src/data/location';

const Map = () => {
  const [activeCategory, setActiveCategory] = useState(0);

  return (
    <Intl>
      {(t, { locale }) => (
        <FadeUp>
          <Root>
            <Wrapper>
              <h2 className="dark">{t.location.map.title}</h2>
              <p className="main-text">{t.location.map.text}</p>
              <Desktop>
                {t.location.map.categories.map((category, index) => (
                  <Category key={index}>
                    <Title onClick={() => setActiveCategory(index)}>
                      <h4 className="dark">{category.title}</h4>
                      <img
                        src={require('src/assets/images/icons/chevron-dark.svg')}
                        alt={category.title}
                        style={{
                          transform:
                            index === activeCategory
                              ? 'scaleY(-1)'
                              : 'scaleY(1)',
                        }}
                      />
                    </Title>
                    <Points active={activeCategory === index}>
                      {category.points.map((point, p) => (
                        <div>
                          {point.color ? (
                            <Line color={point.color} shadow={point.shadow} />
                          ) : (
                            <Letter>{point.letter}</Letter>
                          )}
                          <p className="list dark">{point.text}</p>
                        </div>
                      ))}
                    </Points>
                  </Category>
                ))}
              </Desktop>
            </Wrapper>
            <Image>
              {mapImages.map((image, index) => (
                <img
                  src={require(`src/assets/images/location/map/${image}${
                    locale === 'en' ? '' : '-tc'
                  }.png`)}
                  alt=""
                  style={{ opacity: index === activeCategory ? 1 : 0 }}
                />
              ))}
            </Image>
            <Mobile>
              <img
                src={require(`src/assets/images/location/map/gh-map-mobile${
                  locale === 'en' ? '' : '-tc'
                }.png`)}
                alt="Gryphon House Neighbourhood"
              />
            </Mobile>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin-bottom: ${vwMobile(80)};
  .main-text {
    margin: ${vwMobile(20)} 0 ${vwMobile(40)};
  }
  span {
    font-style: italic;
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(160)};
    .main-text {
      margin: ${vwTablet(40)} 0 ${vwTablet(60)};
      width: ${vwTablet(450)};
    }
  }
  @media ${media.desktop} {
    width: 100%;
    height: ${vwDesktop(960)};
    position: relative;
    background-color: #c1dfec;
    padding: ${vwDesktop(120)} ${vwDesktop(60)};
    margin-bottom: ${vwDesktop(160)};
    .main-text {
      margin: ${vwDesktop(40)} 0 ${vwDesktop(160)};
      width: ${vwDesktop(320)};
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 ${vwMobile(16)};
  @media ${media.tablet} {
    margin: 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    margin: 0;
  }
`;

const Category = styled.div`
  width: 100%;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${vwDesktop(8)} 0 ${vwDesktop(16)};
  border-top: 1px solid ${({ theme }) => theme.color.darkBrown};
  cursor: pointer;
  h4 {
    text-transform: uppercase;
  }
  img {
    width: ${vwDesktop(14)};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
`;

const Points = styled.div`
  max-height: ${(props) => (props.active ? vwDesktop(200) : 0)};
  margin-bottom: ${(props) => (props.active ? vwDesktop(20) : 0)};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  transition-delay: ${(props) => (props.active ? '0.3s' : '0s')};
  overflow-y: hidden;
  overflow-x: visible;
  > div {
    display: flex;
    align-items: center;
  }
  p {
    margin-left: ${vwDesktop(12)};
  }
`;

const Line = styled.div`
  width: ${vwDesktop(20)};
  height: ${vwDesktop(3)};
  background-color: ${(props) => props.color};
  border-radius: ${vwDesktop(1.5)};
`;

const Letter = styled.div`
  width: ${vwDesktop(20)};
  height: ${vwDesktop(20)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${vwDesktop(12)};
  font-weight: 600;
  line-height: 1.33;
`;

const Image = styled.div`
  width: ${vwDesktop(933)};
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
  @media ${media.desktop} {
    display: block;
  }
`;

const Mobile = styled.div`
  background-color: #c1dfec;
  width: 100%;
  height: ${vwMobile(330)};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media ${media.tablet} {
    height: ${vwTablet(790)};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    width: ${vwDesktop(320)};
  }
`;

export default Map;
