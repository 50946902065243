import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Hamburger = (props) => {
  const { isMenuOpen, handleClick } = props;
  return (
    <Root onClick={handleClick}>
      <img
        src={require('src/assets/images/icons/menu-gold.svg')}
        alt="Open Menu"
        style={{ opacity: isMenuOpen ? 0 : 1 }}
      />
      <img
        src={require('src/assets/images/icons/close-gold.svg')}
        alt="Close Menu"
        style={{ opacity: isMenuOpen ? 1 : 0 }}
      />
    </Root>
  );
};

Hamburger.propTypes = {
  isMenuOpen: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  width: ${vwMobile(20)};
  height: ${vwMobile(20)};
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
  @media ${media.tablet} {
    width: ${vwTablet(24)};
    height: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

export default Hamburger;
