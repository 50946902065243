module.exports = {
  defaultLocale: 'tc',
  locales: ['en', 'tc'],
  prerender: true,
  dbProject: 'GH-TW',
  analytics: {
    gtag: '',
    gtmId: 'GTM-K7ZS8WT',
    optimizeId: '',
    hotjarId: '',
  },
  recaptcha: '6Lfs_fEjAAAAAJt_vSYBl7pfNk2bxqhG6z8KEvjB',
  seo: {
    title: 'Gryphon House',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
