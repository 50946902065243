import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const images = [
  require('src/assets/images/creation/gryphon-house-transform-1.jpeg'),
  require('src/assets/images/creation/gryphon-house-transform-2.jpeg'),
  require('src/assets/images/creation/gryphon-house-transform-3.jpeg'),
  require('src/assets/images/creation/gryphon-house-transform-4.jpeg'),
];

const Building = () => {
  const $building = useRef();
  const $images = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, []);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    const windowHeight = e.target.scrollingElement.offsetHeight;
    const targetScrollTop = scrollTop + windowHeight / 2;
    const containerTop = $building.current.offsetTop;
    const containerHeight = $building.current.offsetHeight;

    const position = (targetScrollTop - containerTop) / containerHeight;

    // $images[0].current.style.opacity = (position * 10) / 4;
    // $images[1].current.style.opacity = (position * 10) / 4 - 1 / 4;
    // $images[2].current.style.opacity = (position * 10) / 4 - 1 / 2;
    // $images[3].current.style.opacity = (position * 10) / 4 - 3 / 4;

    $images[0].current.style.opacity = (position * 10) / 1.75;
    $images[1].current.style.opacity = (position * 10) / 1.75 - 1;
    $images[2].current.style.opacity = (position * 10) / 1.75 - 2;
    $images[3].current.style.opacity = (position * 10) / 1.75 - 3;
  };

  return (
    <Root ref={$building}>
      <Wrapper>
        <img
          src={require('src/assets/images/creation/building-fade.jpg')}
          alt=""
          className="mobile"
        />
        {images.map((item, index) => (
          <img
            src={item}
            key={index}
            alt=""
            className="desktop"
            ref={$images[index]}
          />
        ))}
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  @media ${media.desktop} {
    height: 260vh;
    margin-top: ${vwDesktop(-160)};
  }
`;

const Wrapper = styled.div`
  margin: ${vwMobile(-80)} 0 ${vwMobile(80)};
  img {
    width: 100%;
    display: block;
  }
  .desktop {
    display: none;
  }
  @media ${media.tablet} {
    margin: ${vwTablet(-80)} 0 ${vwTablet(160)};
  }
  @media ${media.desktop} {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: sticky;
    -webkit-position: sticky;
    top: 0;
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: ${vwDesktop(1220)};
      height: ${vwDesktop(289)};
      opacity: 0;
    }
  }
`;

export default Building;
