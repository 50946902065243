import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import FadeUp from 'components/animations/FadeUp';
import ParallaxImage from 'components/animations/ParallaxImage';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ImageCallout = (props) => {
  const { image, title, text, parallax } = props;
  const browser = useSelector((state) => state.browser);

  return (
    <Parallax y={parallax} disabled={!browser.is.desktop}>
      <FadeUp>
        <Root {...props}>
          <ParallaxImage src={image} alt={title ? title : ''} />
          <div>
            {title && <h4 className="dark callout">{title}</h4>}
            {text && <p className="p2 dark callout">{text}</p>}
          </div>
        </Root>
      </FadeUp>
    </Parallax>
  );
};

ImageCallout.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.number,
  textWidth: PropTypes.number,
  row: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rightText: PropTypes.bool,
  margin: PropTypes.bool,
};

const Root = styled.div`
  padding: ${(props) => (props.fullWidth ? 0 : `0 ${vwMobile(16)}`)};
  margin-bottom: ${vwMobile(40)};
  img {
    width: 100%;
    display: block;
    box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.06),
      0 6px 16px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: ${vwMobile(12)};
  }
  h4 {
    margin-bottom: ${vwMobile(4)};
    text-transform: uppercase;
  }
  > div {
    padding: ${(props) => (props.fullWidth ? `0 ${vwMobile(16)}` : 0)};
  }
  p span {
    font-weight: 600;
    &.small {
      font-weight: 500;
    }
  }
  @media ${media.tablet} {
    padding: ${(props) => (props.fullWidth ? 0 : `0 ${vwTablet(40)}`)};
    margin-bottom: ${vwTablet(80)};
    img {
      width: 100%;
      display: block;
      box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.06),
        0 6px 16px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: ${vwTablet(24)};
    }
    h4 {
      margin-bottom: ${vwTablet(4)};
    }
    p {
      max-width: ${vwTablet(450)};
    }
    > div {
      padding: ${(props) => (props.fullWidth ? `0 ${vwTablet(40)}` : 0)};
    }
  }
  @media ${media.desktop} {
    padding: 0;
    width: ${(props) => (props.row ? '100%' : vwDesktop(props.width))};
    display: flex;
    margin-bottom: ${(props) => (props.margin ? vwDesktop(160) : 0)};
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
    align-items: ${(props) => (props.row ? 'flex-end' : 'flex-start')};
    img {
      width: ${(props) => (props.row ? vwDesktop(props.width) : '100%')};
      display: block;
      box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.06),
        0 6px 16px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: ${(props) => (props.row ? 0 : vwDesktop(24))};
      margin-right: ${(props) => (props.row ? vwDesktop(24) : 0)};
    }
    h4 {
      margin-bottom: ${vwDesktop(4)};
    }
    p {
      max-width: ${(props) =>
        props.textWidth ? vwDesktop(props.textWidth) : 'none'};
    }
    > div {
      padding: 0;
      width: ${(props) => (props.rightText ? '50%' : 'auto')};
      align-self: ${(props) =>
        props.rightText || props.row ? 'flex-end' : 'flex-start'};
    }
  }
`;

export default ImageCallout;
