import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Footer from 'components/footer/Footer';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Page = (props) => {
  const { hideRegister, removeOverflow } = props;
  return (
    <Root removeOverflow={removeOverflow}>
      {props.children}
      <Footer hideRegister={hideRegister} />
    </Root>
  );
};

Page.propTypes = {
  hideRegister: PropTypes.bool,
};

// margin-top accomodates for the sticky menu on desktop, if that changes this might have to be removed
const Root = styled.div`
  width: 100%;
  overflow: hidden;
  @media ${media.desktop} {
    padding-left: ${vwDesktop(100)};
    margin-top: -100vh;
    overflow: ${(props) => (props.removeOverflow ? 'visible' : 'hidden')};
  }
`;

export default Page;
