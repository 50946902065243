import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withController } from 'react-scroll-parallax';

const ParallaxImage = (props) => {
  const { src, alt, parallaxController } = props;

  const handleLoad = () => {
    // updates cached values after image dimensions have loaded
    parallaxController.update();
  };

  return <Root src={src} alt={alt} {...props} onLoad={handleLoad} />;
};

ParallaxImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

const Root = styled.img``;

export default withController(ParallaxImage);
