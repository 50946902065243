import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/Hero';
import TitleBlock from 'components/TitleBlock';
import ContentBlock from 'components/ContentBlock';
import LinkBlock from 'components/LinkBlock';
import ImageCallout from 'components/layout/ImageCallout';
import CompareCaption from 'components/layout/CompareCaption';
import Collage from './sections/Collage';
import Callouts from './sections/Callouts';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Design = () => {
  return (
    <Intl>
      {(t) => (
        <Page>
          <Hero
            subheader={t.design.hero.subheader}
            header={t.design.hero.header}
            video="https://player.vimeo.com/external/505900511.hd.mp4?s=d97ad3d9b15038b560441291f5f8162ae6894a81&amp;profil..."
          ></Hero>
          <Space />
          <ContentBlock
            title={t.design.contentBlocks[0].title}
            body={t.design.contentBlocks[0].text}
            images={['creation/gryphon-house-exterior-entrance.jpg']}
            settings={{
              template: 6,
              rowReverse: true,
            }}
          />
          <ContentBlock
            title={t.design.contentBlocks[1].title}
            body={t.design.contentBlocks[1].text}
            images={[
              'design/gryphon-house-angle-view.jpg',
              'design/british-museum-glass-roof.jpg',
              'creation/london-apartment-facade.jpg',
              'creation/london-bank-district.jpg',
            ]}
            settings={{
              template: 3,
            }}
          />
          <Wrapper>
            <ImageCallout
              title={t.design.callouts[0].title}
              text={t.design.callouts[0].text}
              fullWidth
              image={require('src/assets/images/creation/gryphon-house-east-view-cropped.jpg')}
              parallax={[5, -5]}
              textWidth={598}
              rightText
            />
          </Wrapper>
          <Space small />
          <TitleBlock
            title={t.design.titleBlocks[0].title}
            subTitle={t.design.titleBlocks[0].subtitle}
            text={t.design.titleBlocks[0].text}
          />
          <Collage />
          <TitleBlock
            title={t.design.titleBlocks[1].title}
            subTitle={t.design.titleBlocks[1].subtitle}
            text={t.design.titleBlocks[1].text}
          />
          <Space large />
          <CompareCaption
            fullWidth
            images={[
              require('src/assets/images/design/gryphon-house-living-dining-dark.jpg'),
              require('src/assets/images/design/gryphon-house-living-dining-light.jpg'),
            ]}
            title={t.design.imageCompares.living.title}
            text={t.design.imageCompares.living.text}
            height={400}
          />
          <CompareCaption
            images={[
              require('src/assets/images/residences/kitchen-scheme-dark.jpeg'),
              require('src/assets/images/residences/kitchen-scheme-light.jpeg'),
            ]}
            title={t.design.imageCompares.kitchen.title}
            text={t.design.imageCompares.kitchen.text}
            height={580}
          />
          <Callouts />
          <LinkBlock
            title={t.residences.linkBlock.title}
            body={t.residences.linkBlock.text}
            buttonLabel={t.residences.linkBlock.button}
            bgImageMobile="amenities/gryphon-house-club-lounge.jpeg"
            bgImageDesktop="amenities/gryphon-house-club-lounge.jpeg"
            url="/amenities"
            noLogo
            small
          />
        </Page>
      )}
    </Intl>
  );
};

const Space = styled.div`
  ${(props) => {
    if (props.large) {
      return vw('padding-top', 120, 160, 160);
    } else if (props.small) {
      return vw('padding-top', 40, 80, 0);
    } else {
      return vw('padding-top', 80);
    }
  }}
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)} ${vwDesktop(160)};
  }
`;

export default Design;
