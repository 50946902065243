import React from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import RoundButton from 'components/typography/RoundButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const NotFound = () => {
  return (
    <Page hideRegister>
      <Root>
        <h4 className="subheader moss">404</h4>
        <p>The page you are looking for does not exist.</p>
        <RoundButton to="/" text="back to homepage" transparent />
      </Root>
    </Page>
  );
};

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    padding: ${vwMobile(32)} 0;
  }
  @media ${media.tablet} {
    p {
      padding: ${vwTablet(40)} 0;
    }
  }
  @media ${media.desktop} {
    p {
      padding: ${vwDesktop(40)} 0;
    }
  }
`;

export default NotFound;
