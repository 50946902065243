import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const social = [
  {
    to: 'https://www.facebook.com/GryphondevelopmentTW',
    alt: 'Facebook',
    image: require('src/assets/images/icons/icon-facebook.svg'),
  },
  {
    to: 'https://page.line.me/?accountId=gryphon',
    alt: 'Line',
    image: require('src/assets/images/icons/icon-line.svg'),
  },
];

const Info = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Wrapper>
            <Logo
              src={require('src/assets/images/logos/gryphon-logo.svg')}
              alt="Gryphon"
              className="logo"
            />
            <Contact>
              <h4 className="subheader light">{t.footer.contact.contact}</h4>
              <p className="p2 light">{t.footer.contact.master}</p>
              <a href="tel:04-2255-0556">
                <p className="p2 light">04-2255-0556</p>
              </a>
              <p className="p2 light">{t.footer.contact.down}</p>
            </Contact>
            <Container>
              <h4 className="subheader light">{t.footer.contact.social}</h4>
              <Social>
                {social.map((item, index) => (
                  <a
                    href={item.to}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <Icon src={item.image} alt={item.alt} />
                  </a>
                ))}
              </Social>
            </Container>
          </Wrapper>
          <Disclaimer className="footnote light">
            {t.footer.contact.disclaimer}
            <br />
            {t.footer.contact.disclaimer2}
          </Disclaimer>
          <Web className="footnote light">
            Website By{' '}
            <a
              href="https://www.bamdigital.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="light"
            >
              Bam Digital
            </a>
          </Web>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.darkBrown};
  padding: ${vwMobile(40)} ${vwMobile(16)} ${vwMobile(92)};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h4 {
    margin: ${vwMobile(40)} 0 ${vwMobile(12)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(40)} ${vwTablet(140)};
    h4 {
      margin: ${vwTablet(60)} 0 ${vwTablet(12)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)} ${vwDesktop(60)} ${vwDesktop(40)};
    text-align: left;
    .logo {
      order: 2;
    }
    h4 {
      margin: 0 0 ${vwDesktop(12)};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vwMobile(106)};
  @media ${media.tablet} {
    width: ${vwTablet(124)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(155)};
  }
`;

const MasterLogo = styled.img`
  display: block;
  width: ${vwMobile(200)};
  margin: 0 auto;
  @media ${media.tablet} {
    width: ${vwTablet(280)};
  }
  @media ${media.desktop} {
    margin: 0;
    width: ${vwDesktop(300)};
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${vwMobile(12)};
  > a:nth-of-type(2) {
    margin-left: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    margin-top: ${vwTablet(12)};
    > a:nth-of-type(2) {
      margin-left: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    justify-content: flex-start;
    margin-top: ${vwDesktop(12)};
    a > img {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a:hover > img {
      opacity: 0.8;
    }
    > a:nth-of-type(2) {
      margin-left: ${vwDesktop(12)};
    }
  }
`;

const Contact = styled.div`
  a {
    display: block;
  }
  @media ${media.desktop} {
    order: 1;
    width: ${vwDesktop(237)};
    text-align: left;
    p {
      white-space: nowrap;
    }
    a {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a:hover {
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  @media ${media.desktop} {
    order: 3;
    width: ${vwDesktop(237)};
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const Icon = styled.img`
  display: block;
  width: ${vwMobile(28)};
  height: ${vwMobile(28)};
  @media ${media.tablet} {
    width: ${vwTablet(28)};
    height: ${vwTablet(28)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(28)};
    height: ${vwDesktop(28)};
  }
`;

const Disclaimer = styled.p`
  margin-top: ${vwMobile(40)};
  opacity: 0.7;
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(60)} auto 0;
    width: ${vwDesktop(920)};
    text-align: center;
  }
`;

const Web = styled.p`
  text-align: center;
  opacity: 0.7;
  margin-top: ${vwMobile(20)};
  a {
    text-decoration: underline;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
    &:hover {
      opacity: 0.8;
    }
  }
  @media ${media.tablet} {
    margin-top: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(20)};
  }
`;

export default Info;
