import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Kerrisdale = (props) => {
  const { margin, line, active } = props;
  return (
    <Root>
      <Logo
        src={require('src/assets/images/logos/est-kerrisdale-vancouver.png')}
        alt="Kerrisdale"
        margin={margin}
        key={active}
      />
      {line && <Line />}
    </Root>
  );
};

Kerrisdale.propTypes = {
  margin: PropTypes.bool,
  line: PropTypes.bool,
};

const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInCss = css`
  animation-name: ${FadeIn};
  animation-duration: ${({ theme }) => theme.animation.time};
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

const Root = styled.div`
  opacity: 0;

  ${FadeInCss}
`;

const Logo = styled.img`


  width: ${vwMobile(91)};
  display: block;
  margin-bottom: ${(props) => (props.margin ? vwMobile(12) : 0)};
  @media ${media.tablet} {
    width: ${vwTablet(114)};
    margin-bottom: ${(props) => (props.margin ? vwTablet(16) : 0)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(114)};
    margin-bottom: ${(props) => (props.margin ? vwDesktop(16) : 0)};
    margin-left: ${vwDesktop(33)};
    margin-right: ${vwDesktop(33)};
  }
`;

const LineAnimation = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  51% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
`;

const LineAnimationCss = css`
  animation-name: ${LineAnimation};
  animation-duration: 2s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-iteration-count: infinite;
  animation-delay: 4s;
`;

const Line = styled.div`
  width: 1px;
  height: ${vwMobile(20)};
  margin: 0 auto;
  background-image: linear-gradient(
    179deg,
    #f5d696 -3%,
    #f4e4a8 21%,
    #edd397 47%,
    #deb66d 67%,
    #dea960 100%
  );
  ${LineAnimationCss}
  @media ${media.tablet} {
    height: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    height: ${vwDesktop(20)};
    transform: scaleY(0);
   
  }
`;

export default Kerrisdale;
