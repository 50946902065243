import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Page from 'components/Page';
import Hero from './sections/Hero';
import TitleBlock from 'components/TitleBlock';
import LinkBlock from 'components/LinkBlock';
import ContentBlock from 'components/ContentBlock';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Home = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            {window.__PRERENDERING && <Overlay />}
            <Hero />
            <Page>
              <Space />
              <TitleBlock
                title={t.home.intro.title}
                text={t.home.intro.text}
                centerText
                tabletLeft
              />
              <Space />
              <ContentBlock
                title={t.home.contentBlock[0].title}
                body={t.home.contentBlock[0].body}
                images={['home/gryphon-house-aerial-glow.jpg']}
                settings={{ template: 6, rowReverse: true }}
                leftPadding
                largerTitle
                lessMobilePadding
              />
              <ContentBlock
                title={t.home.contentBlock[1].title}
                body={t.home.contentBlock[1].body}
                images={['home/gryphon-house-lobby-widecrop.jpg']}
                settings={{ template: 6, rowReverse: false }}
                leftPadding
                lessMobilePadding
              />
              <ContentBlock
                title={t.home.contentBlock[2].title}
                body={t.home.contentBlock[2].body}
                images={[
                  'home/church-exterior.jpg',
                  'location/kerrisdale-clock.jpg',
                  'location/students-in-uniform.jpg',
                ]}
                settings={{
                  template: 1,
                  rowReverse: true,
                }}
                leftPadding
                lessMobilePadding
              />
              <ContentBlock
                title={t.home.contentBlock[3].title}
                body={t.home.contentBlock[3].body}
                images={['home/gryphon-house-building-left.jpg']}
                settings={{ template: 6, rowReverse: false }}
                leftPadding
                lessMobilePadding
              />
              <ContentBlock
                title={t.home.contentBlock[4].title}
                body={t.home.contentBlock[4].body}
                images={[
                  'home/arbutus-greenway-cropped.jpg',
                  'creation/gryphon-house-sidewalk.jpg',
                  'location/west-blvd-aerial-closeup.jpg',
                  'home/afternoon-tea.jpg',
                ]}
                settings={{ template: 4, rowReverse: true }}
                leftPadding
                lessMobilePadding
              />
              <LinkBlock
                title={t.amenities.linkBlock.title}
                body={t.amenities.linkBlock.text}
                buttonLabel={t.amenities.linkBlock.button}
                bgImageMobile="residences/artist-in-grand-hall-flipped.jpg"
                bgImageDesktop="residences/artist-in-grand-hall-flipped.jpg"
                url="/philosophy"
                noLogo
                small
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.main`
  @media ${media.desktop} {
    /* margin-top: -100vh; */
  }
`;

const Space = styled.div`
  padding-top: ${vwMobile(80)};
  @media ${media.tablet} {
    padding-top: ${vwTablet(160)};
  }
  @media ${media.desktop} {
    padding-top: ${vwDesktop(160)};
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: black;
  transition: 0.4s ${({ theme }) => theme.animation.timingFunction};
  pointer-events: none;
`;

export default Home;
