import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import GoogleMapBlock from 'components/GoogleMapBlock';

import vw from 'src/styles/utils';

const Contact = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page>
              <Hero subheader={t.contact.subheader} header={t.contact.header}>
                <HeroImage
                  src={require('src/assets/images/contact/illustration-bell.png')}
                  alt=""
                />
              </Hero>
              <Space />
              <GoogleMapBlock
                title={t.contact.hk.title}
                pretext={t.contact.hk.masterReal}
                phone={t.contact.hk.phone}
                address={t.contact.hk.address}
                logo={require('src/assets/images/contact/gh-map-logo-highlight.png')}
                zoom={14}
                center={{
                  lat: 24.153563165209963,
                  lng: 120.64399418458308,
                }}
                logoCoord={{
                  lat: 24.153563165209963,
                  lng: 120.64399418458308,
                }}
                directions="No.+708,+Huiwen+Rd,+Nantun+District,+Taichung+City,+Taiwan+408/data=!4m2!3m1!1s0x34693d94c4a55e95:0x39625b224a16fb39?sa=X&ved=2ahUKEwjwytXWr9n7AhVmjIkEHXivCoEQ8gF6BAgWEAE"
              />
              <GoogleMapBlock
                title={t.contact.vancouver.title}
                phone="604-620-8295"
                email="sales@gryphonhousevancouver.com"
                address={
                  <>
                    Marine Gateway,
                    <br /> 450 SW Marine Dr Floor 18,
                    <br /> Vancouver, BC
                    <br /> V5X 0C3
                  </>
                }
                zoom={13}
                center={{
                  lat: 49.21028555752316,
                  lng: -123.11555857113534,
                }}
                logoCoord={{
                  lat: 49.21028555752316,
                  lng: -123.11555857113534,
                }}
                directions="450+SW+Marine+Dr+Floor+18,+Vancouver,+BC+V5X+0C3/@49.2101629,-123.1155693,17z/data=!4m13!1m7!3m6!1s0x548674f688d4124f:0x79922c1d77ec166f!2s450+SW+Marine+Dr+Floor+18,+Vancouver,+BC+V5X+0C3!3b1!8m2!3d49.2101629!4d-123.1155693!3m4!1s0x548674f688d4124f:0x79922c1d77ec166f!8m2!3d49.2101629!4d-123.1155693"
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.div``;

const HeroImage = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${vw('width', 50, 99)}
  ${vw('bottom', 120, 150, 110)}
`;

const Space = styled.div`
  ${vw('padding-top', 80)}
`;

export default Contact;
