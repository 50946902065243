import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';
import LinkBlock from 'components/LinkBlock';
import Curator from './sections/Curator';
import Callouts from './sections/Callouts';

import vw from 'src/styles/utils';

const Amenities = () => {
  return (
    <Intl>
      {(t) => (
        <Page>
          <Hero
            subheader={t.amenities.hero.subheader}
            header={t.amenities.hero.header}
            video="https://player.vimeo.com/external/505900501.hd.mp4?s=f7dd02e7ec1fe13a6a46915ce417d417bc213803&amp;profil..."
          ></Hero>
          <Space />
          <ContentBlock
            title={t.amenities.contentBlocks[0].title}
            body={t.amenities.contentBlocks[0].body}
            images={t.amenities.contentBlocks[0].images}
            settings={t.amenities.contentBlocks[0].settings}
          ></ContentBlock>
          <Callouts />
          <Curator />
          <Space large />
          <ContentBlock
            title={t.amenities.contentBlocks[1].title}
            body={t.amenities.contentBlocks[1].body}
            images={t.amenities.contentBlocks[1].images}
            settings={t.amenities.contentBlocks[1].settings}
            button={{
              label: t.amenities.contentBlocks[1].buttonLabel,
              href: 'https://gryphonliving.com/royalcurator/',
            }}
          ></ContentBlock>
          <LinkBlock
            title={t.amenities.linkBlock.title}
            body={t.amenities.linkBlock.text}
            buttonLabel={t.amenities.linkBlock.button}
            bgImageMobile="residences/artist-in-grand-hall-flipped.jpg"
            bgImageDesktop="residences/artist-in-grand-hall-flipped.jpg"
            url="/philosophy"
            noLogo
            small
          />
        </Page>
      )}
    </Intl>
  );
};

const Space = styled.div`
  ${(props) =>
    props.large ? vw('padding-top', 120, 160, 160) : vw('padding-top', 80)}
`;

export default Amenities;
