import React from 'react';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import RoundButton from 'components/typography/RoundButton';
import FadeUp from 'components/animations/FadeUp';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import Intl from 'components/Intl';

import {
  Template1,
  Template2,
  Template3,
  Template4,
  Template5,
  Template6,
} from 'src/styles/Templates';

const ContentBlock = (props) => {
  const {
    subtitle,
    title,
    body,
    button,
    images,
    video,
    handleVideo,
    settings,
    parallaxController,
    children,
    leftPadding,
    largerTitle,
    lessMobilePadding,
  } = props;
  const Templates = [
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
  ];

  const handleLoad = () => {
    parallaxController.update();
  };
  return (
    <FadeUp>
      <Intl>
        {(t, { getLocaleURL, locale }) => (
          <>
            <Root
              settings={settings}
              leftPadding={leftPadding}
              lessMobilePadding={lessMobilePadding}
            >
              <Collage template={Templates[settings.template - 1]}>
                {images.map((image, i) => (
                  <Parallax y={[15, -15]}>
                    <img
                      key={i}
                      src={require(`src/assets/images/${image}`)}
                      alt=""
                      onLoad={handleLoad}
                    />
                  </Parallax>
                ))}
              </Collage>
              <Section
                rowReverse={settings.rowReverse}
                largerTitle={largerTitle}
              >
                {subtitle && <h4 className="subheader moss">{subtitle}</h4>}
                {title}
                <p>{body}</p>
                {button && (
                  <BtnWrapper>
                    <RoundButton
                      text={button.label}
                      to={getLocaleURL() + button.path}
                      href={button.href}
                      transparent
                    />
                  </BtnWrapper>
                )}
                {video && (
                  <Button className="button purple" onClick={handleVideo}>
                    <img
                      src={require('src/assets/images/icons/play-video.png')}
                      alt="play"
                    />
                    <span>{video.label}</span>
                  </Button>
                )}
              </Section>
              {children}
            </Root>
          </>
        )}
      </Intl>
    </FadeUp>
  );
};

export default withController(ContentBlock);

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.darkBrown};
  ${vw('padding-right', 16, 40, 0)}
  ${vw('padding-left', 16, 40, 0)}
  ${(props) =>
    props.lessMobilePadding
      ? vw('margin-bottom', 80, 240, 160)
      : vw('margin-bottom', 160, 240, 160)}

  @media ${media.desktop} {
    flex-direction: ${(props) =>
      props.settings.rowReverse ? 'row-reverse' : 'row'};
    justify-content: ${(props) =>
      props.settings.rowReverse ? 'flex-end' : 'flex-start'};
    align-items: center;
    padding-left: ${(props) =>
      props.settings.rowReverse || props.leftPadding
        ? vwDesktop(162)
        : vwDesktop(60)};
  }
`;

const Section = styled.div`
  ${vw('width', 288, 450, 391)}

  h3, h2 {
    ${vw('margin-bottom', 20, 40)}
    ${(props) => props.largerTitle && vw('width', 288, 450, 416)}

    span {
      font-style: italic;
    }
  }

  h4 {
    ${vw('margin-bottom', 32, 80)}
  }
  @media ${media.desktop} {
    ${(props) =>
      props.rowReverse
        ? `margin-right: ${vwDesktop(128)};`
        : `margin-left: ${vwDesktop(128)};`}
  }
`;

const Collage = styled.div`
  ${(props) => props.template}
  ${vw('margin-bottom', 40, 80, 0)}
  img {
    object-fit: cover;
    object-position: center;
  }
`;

const BtnWrapper = styled.div`
  ${vw('margin-top', 20, 40)}
`;

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${vw('margin-top', 16, 32)}
  img {
    ${vw('width', 24, 28)}
    ${vw('height', 24, 28)}
  }
  span {
    ${vw('margin-left', 8, 12)}
  }
`;
