import React from 'react';
import styled from 'styled-components';

export default function GradientText(props) {
  const { children } = props;
  return (
    <Root
      style={{
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
      }}
    >
      <span></span>
      {children}
    </Root>
  );
}

const Root = styled.span`
  background-image: linear-gradient(
    109deg,
    #f5d696 24%,
    #f4e4a8 36%,
    #edd397 49%,
    #deb66d 58%,
    #dea960 75%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
`;
