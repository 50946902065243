import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import ImageCallout from 'components/layout/ImageCallout';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const data = [
  {
    image: require('src/assets/images/creation/gryphon-house-lobby.jpg'),
    width: 495,
  },
  {
    image: require('src/assets/images/amenities/gryphon-house-spa.jpg'),
    width: 495,
  },
  {
    image: require('src/assets/images/amenities/gryphon-house-gym.jpg'),
    width: 805,
  },
  {
    image: require('src/assets/images/amenities/gryphon-house-club-lounge.jpeg'),
    width: 910,
    row: true,
  },
  {
    image: require('src/assets/images/amenities/gryphon-house-business-centre.jpg'),
    width: 391,
  },
  {
    image: require('src/assets/images/amenities/gryphon-house-car-park-foyer.jpg'),
    width: 700,
  },
];

const Callouts = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Wrapper margin>
            <ImageCallout
              image={data[0].image}
              title={t.amenities.callouts[0].title}
              text={t.amenities.callouts[0].text}
              row={data[0].row}
              width={data[0].width}
              parallax={[20, -20]}
              textWidth={360}
            />
            <ImageCallout
              image={data[1].image}
              title={t.amenities.callouts[1].title}
              text={t.amenities.callouts[1].text}
              row={data[1].row}
              width={data[1].width}
              parallax={[10, -20]}
              textWidth={230}
            />
          </Wrapper>
          <Wrapper end>
            <ImageCallout
              image={data[2].image}
              title={t.amenities.callouts[2].title}
              text={t.amenities.callouts[2].text}
              row={data[2].row}
              width={data[2].width}
              parallax={[20, -20]}
              textWidth={494}
            />
          </Wrapper>
          <ImageCallout
            image={data[3].image}
            title={t.amenities.callouts[3].title}
            text={t.amenities.callouts[3].text}
            row={data[3].row}
            width={data[3].width}
            parallax={[20, -20]}
          />
          <Wrapper margin>
            <ImageCallout
              image={data[4].image}
              title={t.amenities.callouts[4].title}
              text={t.amenities.callouts[4].text}
              row={data[4].row}
              width={data[4].width}
              parallax={[20, -20]}
              textWidth={300}
            />
            <ImageCallout
              image={data[5].image}
              title={t.amenities.callouts[5].title}
              text={t.amenities.callouts[5].text}
              row={data[5].row}
              width={data[5].width}
              parallax={[20, -20]}
              textWidth={380}
            />
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  padding: 0 0 ${vwMobile(80)};
  @media ${media.tablet} {
    padding: 0 0 ${vwTablet(80)};
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)} ${vwDesktop(160)};
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: ${(props) => (props.end ? 'flex-end' : 'space-between')};
    margin-right: ${(props) => (props.end ? vwDesktop(104) : 0)};
    > div:nth-of-type(2) {
      margin-top: ${(props) => (props.margin ? vwDesktop(240) : 0)};
    }
  }
`;

export default Callouts;
