import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReCaptcha } from 'react-recaptcha-v3';

import Intl from 'components/Intl';
import RoundButton from 'components/typography/RoundButton';
import Input from './Input';
import Select from './Select';

import vw from 'src/styles/utils';

import Insight from 'src/plugins/insight';

import { recaptcha } from 'src/config/app.conf';

// form is submitting to https://docs.google.com/spreadsheets/d/15c0J86l0aAKFo8cGc2sASle2um4VkYu6ACfdaoW4Fpc/

const Form = () => {
  const [isYesRealtor, setIsYesRealtor] = useState(false);
  const [token, setToken] = useState('');

  const $recaptcha = useRef();

  useEffect(() => {
    // Hides spam trap
    document.getElementById('are_you_simulated').style.display = 'none';
  }, []);

  const $form = useRef();

  const handleRealtor = (e) => {
    if (e.target.value.toLowerCase() === 'true') {
      setIsYesRealtor(true);
    } else {
      setIsYesRealtor(false);
    }
  };

  let submitting = true;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await updateToken();

    var form = $form.current;
    var missing = '';
    var required = { contact_email: 'Email' };
    var customRequired = document.querySelectorAll(
      'input:required, textarea:required, select:required'
    );
    var questionsRequired = {
      //agent: 'Are you an agent',
    };
    // Adds custom required inputs to the 'required' object
    for (var i = 0; i < customRequired.length; i++) {
      required[customRequired[i].id] = customRequired[
        i
      ].parentNode.firstElementChild.innerHTML.replace('*', '');
    }
    // Adds required question inputs to the 'required' object
    for (var key in questionsRequired) {
      console.log(questionsRequired);
      required[key] = questionsRequired[key];
    }

    // Iterates through required fields and adds any that have
    // not been populated to 'missing' list
    for (var key in required) {
      var elements = Array.from(
        document.querySelectorAll("[id^='" + key + "']")
      );

      if (elements.length > 0) {
        var missing_field = true;

        elements.forEach(function (el) {
          if (
            el.length < 1 ||
            (el &&
              ((el.type === 'checkbox' && el.checked) ||
                (el.type === 'radio' && el.checked) ||
                (el.type !== 'radio' && el.type !== 'checkbox' && el.value) ||
                (document.getElementById(key + '_other_text') &&
                  document.getElementById(key + '_other_text').value)))
          ) {
            missing_field = false;
          }
        });

        if (missing_field) {
          // missing += '- ' + required[key] + '\r\n';
          missing += ' ';
        }
      }
    }

    // Tests email input value against RFC 5322 Official Standard Email Regex
    var email = document.getElementById('contact_email').value;
    if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    ) {
      // missing += '- Email is invalid\r\n';
      missing += ' ';
    }

    if (missing !== '') {
      // alert('The following fields are incomplete:\r\n' + missing);
      alert('Please fill out the required fields.');
      return false;
    }

    // Prevents duplicate submissions
    if (submitting) {
      let name =
        document.getElementById('contact_first_name').value +
        ' ' +
        document.getElementById('contact_last_name').value;
      let email = document.getElementById('contact_email').value;

      let pageName = 'New Registrant - HK';

      await Insight({ name: name, email: email, pageName: pageName });
      form.submit();

      return false;
    }
    submitting = true;
  };

  const updateToken = async () => {
    // you will get a new token in verifyCallback
    await $recaptcha.current.execute();
  };

  const verifyCallback = async (token) => {
    setToken(token);
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root
          action="https://hook.us1.make.com/u6h54xmeqij6ocs71q1h7cape8peo2i7"
          method="post"
          accept-charset="UTF-8"
          ref={$form}
        >
          <ReCaptcha
            ref={$recaptcha}
            sitekey={recaptcha}
            action="registration"
            verifyCallback={verifyCallback}
          />
          <input
            id="g-recaptcha-response"
            name="g-recaptcha-response"
            className="g-recaptcha-response"
            value={token}
            type="hidden"
          />
          <input name="utf8" type="hidden" value="&#x2713;" />
          <input
            type="hidden"
            name="authenticity_token"
            value="pAv4U6Bb6DchnUOtLQwFwJ+aHmcYG853mtg3qfcQ4bvmduende3ynv8nI5aqNaE8nV/sFAUP9lwZjq+zNyF/Xg=="
          />
          <input
            id="redirect_success"
            name="redirect_success"
            type="hidden"
            value={window.location.origin + getLocaleURL() + '/thank-you'}
          />
          <input
            type="hidden"
            name="redirect_error"
            id="redirect_error"
          ></input>
          <input
            type="text"
            name="are_you_simulated"
            id="are_you_simulated"
            placeholder="Leave this field blank"
          ></input>
          <input
            type="hidden"
            name="source"
            id="source"
            value="TW Web Registration"
          ></input>
          <Input
            name="contact[first_name]"
            id="contact_first_name"
            type="text"
            label={t.registerPage.firstName}
            required
          />
          <Input
            name="contact[last_name]"
            id="contact_last_name"
            type="text"
            label={t.registerPage.lastName}
            required
          />
          <Input
            name="contact[phone]"
            id="contact_phone"
            type="tel"
            label={t.registerPage.phone}
            required
          />
          <Input
            name="contact[email]"
            id="contact_email"
            type="email"
            label={t.registerPage.email}
            required
          />
          {/* <Select
            name="agent"
            id="agent"
            required
            label={t.registerPage.realtor}
            options={t.registerPage.realtorOptions}
            handleValue={handleRealtor}
          />
          {isYesRealtor && (
            <Input
              name="contact[brokerage_name]"
              id="contact[brokerage_name]"
              label={t.registerPage.ifYesRealtor}
              type="text"
            />
          )} */}
          {/* <Input
            name="answers[7369][answers]"
            id="answers_7369"
            label={t.registerPage.howHeard}
            type="text"
          /> */}

          <Select
            name="residency"
            id="residency"
            // required
            label={t.registerPage.residency}
            options={t.registerPage.residencyOptions}
            // handleValue={handleRealtor}
          />
          <Select
            name="budget"
            id="budget"
            // required
            label={t.registerPage.budget}
            options={t.registerPage.budgetOptions}
            // handleValue={handleRealtor}
          />
          <Select
            name="answers[7369][answers]"
            id="answers_7369"
            // required
            label={t.registerPage.howHeard}
            options={t.registerPage.howHeardOptions}
            handleValue={handleRealtor}
          />
          <label htmlFor="checkbox" className={`footnote dark`}>
            {t.registerPage.checkbox}
          </label>
          <br />
          <br />
          <br />
          <RoundButton
            handleClick={(e) => handleFormSubmit(e)}
            text={t.registerPage.submit}
          />
        </Root>
      )}
    </Intl>
  );
};

export default Form;

const Root = styled.form`
  ${vw('padding-top', 60, 60)}
  ${vw('padding-bottom', 60, 80)}
  #g-recaptcha {
    display: none;
  }
`;
