import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { useSelector } from 'react-redux';

import FadeUp from 'components/animations/FadeUp';
import ParallaxImage from 'components/animations/ParallaxImage';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Images = () => {
  const browser = useSelector((state) => state.browser);
  return (
    <FadeUp>
      <Root>
        <Parallax y={[0, -40]} disabled={!browser.is.desktop}>
          <ParallaxImage
            src={require('src/assets/images/creation/gryphon-house-lobby.jpg')}
            alt="Gryphon House Lobby"
            className="small"
          />
        </Parallax>
        <Parallax y={[20, -20]} disabled={!browser.is.desktop}>
          <ParallaxImage
            src={require('src/assets/images/creation/gryphon-house-amenity-dining.jpg')}
            alt="Gryphon House Amenity Dining"
            className="large"
          />
        </Parallax>
      </Root>
    </FadeUp>
  );
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)};
  margin-top: ${vwMobile(-80)};
  img {
    box-shadow: ${({ theme }) => theme.shadow};
    margin-bottom: ${vwMobile(20)};
    display: block;
    width: 100%;
    height: auto;
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(40)};
    margin-top: 0;
    img {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)} ${vwDesktop(160)};
    display: flex;
    justify-content: space-between;
    img {
      margin-bottom: 0;
      &.small {
        width: ${vwDesktop(494)};
        height: ${vwDesktop(720)};
      }
      &.large {
        width: ${vwDesktop(597)};
      }
    }
    .parallax-outer:first-of-type {
      width: ${vwDesktop(494)};
      height: ${vwDesktop(720)};
    }
    .parallax-outer:last-of-type {
      width: ${vwDesktop(597)};
      height: ${vwDesktop(697)};
      margin-top: ${vwDesktop(240)};
    }
  }
`;

export default Images;
