import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TwentyTwenty from 'react-twentytwenty';

import FadeUp from 'components/animations/FadeUp';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ImageCompare = (props) => {
  const { left, right, tall } = props;
  return (
    <FadeUp>
      <Root tall={tall}>
        <Mobile>
          <img src={left} alt="" />
          <img src={right} alt="" />
        </Mobile>
        <Desktop>
          <TwentyTwenty
            left={<img src={left} alt="" />}
            right={<img src={right} alt="" />}
            slider={
              <Slider>
                <div>
                  <img
                    src={require('src/assets/images/icons/icon-chevron-light.svg')}
                    alt="slider"
                  />
                  <img
                    src={require('src/assets/images/icons/icon-chevron-light.svg')}
                    alt="slider"
                  />
                </div>
              </Slider>
            }
          />
        </Desktop>
      </Root>
    </FadeUp>
  );
};

ImageCompare.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
};

const Root = styled.div`
  margin: ${vwMobile(40)} auto
    ${(props) => (props.tall ? vwMobile(40) : vwMobile(80))};
  width: ${vwMobile(288)};
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
  div {
    height: 100%;
  }
  @media ${media.tablet} {
    margin: ${vwTablet(80)} auto;
    width: ${vwTablet(688)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(80)} auto
      ${(props) => (props.tall ? vwDesktop(80) : vwDesktop(160))};
    width: ${vwDesktop(1220)};
    height: ${(props) => (props.tall ? vwDesktop(800) : vwDesktop(720))};
    img {
      height: 100%;
    }
  }
`;

const Mobile = styled.div`
  img {
    box-shadow: ${({ theme }) => theme.shadow};
  }
  img:first-of-type {
    margin-bottom: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    img:first-of-type {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    box-shadow: ${({ theme }) => theme.shadow};
  }
`;

const Slider = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.darkBrown};
  cursor: pointer;
  div {
    width: ${vwDesktop(48)};
    height: ${vwDesktop(48)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.darkBrown};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${vwDesktop(10)};
  }
  img {
    width: ${vwDesktop(7)} !important;
    height: auto;
    display: block;
    &:last-of-type {
      transform: scaleX(-1);
    }
  }
`;

export default ImageCompare;
