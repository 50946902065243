import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BackgroundVideo = (props) => {
  const { src } = props;

  return (
    <Root>
      <video
        src={src}
        playsInline
        autoPlay
        muted={true}
        loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </Root>
  );
};

BackgroundVideo.propTypes = {
  src: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export default BackgroundVideo;
