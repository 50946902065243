import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animations/FadeUp';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const FullImage = (props) => {
  const { image, alt, croppedMobile } = props;
  return (
    <FadeUp>
      <Root croppedMobile={croppedMobile} src={image} alt={alt} />
    </FadeUp>
  );
};

FullImage.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  croppedMobile: PropTypes.bool,
};

const Root = styled.img`
  width: 100%;
  height: ${(props) => (props.croppedMobile ? vwMobile(360) : 'auto')};
  display: block;
  object-fit: cover;
  object-position: left;
  box-shadow: ${({ theme }) => theme.shadow};
  @media ${media.tablet} {
    height: ${(props) => (props.croppedMobile ? vwTablet(656) : 'auto')};
  }
  @media ${media.desktop} {
    height: auto;
    width: ${vwDesktop(1220)};
    margin: 0 auto;
  }
`;

export default FullImage;
