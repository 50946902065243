import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Lines = (props) => {
  const { light, mobile } = props;
  return (
    <Root light={light} mobile={mobile} {...props}>
      <span />
      <span />
    </Root>
  );
};

Lines.propTypes = {
  light: PropTypes.bool,
  mobile: PropTypes.bool,
};

const Root = styled.div`
  display: ${(props) => (props.mobile ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  height: ${vwMobile(5)};
  width: 100%;
  position: relative;
  span {
    height: 1px;
    width: 100%;
    background-image: ${(props) =>
      props.light
        ? 'none'
        : `url(${require('src/assets/images/general/gold-foil.jpg')})`};
    background-size: cover;
    background-position: center;
    background-color: ${(props) =>
      props.light ? props.theme.color.taupe : 'none'};
  }
  @media ${media.tablet} {
    height: ${vwTablet(5)};
  }
  @media ${media.desktop} {
    display: flex;
    height: ${vwDesktop(5)};
  }
`;

export default Lines;
